"use strict";

import loadScript from "../load-script";
import { onFind } from "../init-modules-in-scope";
import { trigger, on, off } from "../dom-utils";
import  merge  from "deepmerge"

const INITIALIZED_EVENT = 'googleMap/initialized';

const defaultOptions = {
    mapOptions: {
        center: {lat: 47.836249, lng: 13.0615383},
        zoom: 12,
        scrollwheel: false
    },
    pois : [],
    poiStyles : null,
    infoBoxOptions : {},
    clustering: true,
    clusteringOptions: {},
    onActivateMarker: null,
    onDeactivateMarker: null
};

const defaultSelectors = {
    base: '.js-google-map',
};

/* General */
export function loadGoogleMapsAPI() {
    if (!_config.googleMapAPIKey) {
        return Promise.reject(new Error('google map key is not set. Please set _config.googleMapAPIKey'));
    }

    let apiUrl = "https://maps.googleapis.com/maps/api/js?key=" + _config.googleMapAPIKey + "&language=" + _config.lang;

    // add google maps libraries (https://developers.google.com/maps/documentation/javascript/libraries)
    // possible libs: drawing, geometry, places, visualization
    if (_config.googleMapsLibraries) {
        // must be an array containing the needed libs (e.g. ["geometry","places"])
        apiUrl = apiUrl + "&libraries=" + _config.googleMapsLibraries.join(',');
    }

    return loadScript(apiUrl);
}

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, (baseElement) => {
        loadGoogleMapsAPI().then(() => {
            createGoogleMap(
                baseElement,
                merge.all([defaultOptions, options]),
                merge.all([defaultSelectors, selectors])
            );
        })
    });
}

export async function createGoogleMap(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    let api = {
        getMapInstance,
        setMarker,
        addMarker,
        centerMap,
        getMarkers,
        getClusterByMarker,
        getActiveMarker,
        removeAllMarker,
        activateMarker,
        deactivateMarker,
        setActiveMarkerStyle,
        setDefaultMarkerStyle,
        scrollToMarkers,
        showInfoBoxByMarker,
        closeInfoBoxByMarker,
        setActiveClusterStyle,
        setDefaultClusterStyle
    };

    await loadGoogleMapsAPI()

    if(options.poiStyles instanceof Function){
        options.poiStyles = options.poiStyles();
    }

    if(options.infoBoxOptions instanceof Function){
        options.infoBoxOptions = options.infoBoxOptions();
    }

    let googleMap = new google.maps.Map(baseElement, options.mapOptions);
    let MapMarker = [];
    let MapMarkeractive = null;

    let clusteringPromise;
    if(options.clustering){
        clusteringPromise = import("gmaps-marker-clusterer").then(function () {
            return new MarkerClusterer(
                googleMap,
                [],
                options.clusteringOptions.default || options.clusteringOptions // support single object (only default styling) as well as cluster styles object
            );
        });
    }

    if(options.pois){
        setMarker(options.pois);
    }

    function getMapInstance(){
        return googleMap;
    }

    function getMarkers() {
        return MapMarker;
    }

    function getActiveMarker() {
        return MapMarkeractive;
    }


    function setMarker(markers){
        removeAllMarker();
        addMarker(markers);
        centerMap();
    }

    function addMarker(markers){
        markers.map(marker => {
            let position = new google.maps.LatLng(marker.lat, marker.lng);
            let poiStyle;

            if (marker.poiStyle) {
                if (options.poiStyles) {
                    if (options.poiStyles[marker.poiStyle]) {
                        poiStyle = options.poiStyles[marker.poiStyle];
                    } else {
                        poiStyle = options.poiStyles.default;
                        console.error(`Could not find poi style '${marker.poiStyle}' in `, options.poiStyles);
                    }
                } else {
                    console.error('Poi styles ar not set');
                }
            } else if (options.poiStyles && options.poiStyles.default) {
                poiStyle = options.poiStyles.default;
            }


            let newMarker = new google.maps.Marker({
                map: googleMap,
                position: position,
                icon: poiStyle ? poiStyle.default : null,
                poiStyle: poiStyle,
                dataId: marker.id,
                detailInfo: marker.detailInfo,
                detailInfoBoxUrl: marker.detailInfoBoxUrl
            });


            if (options.onActivateMarker && options.onDeactivateMarker) {
                google.maps.event.addListener(newMarker, 'click', function () {
                    if (MapMarkeractive === newMarker) {
                        deactivateMarker(newMarker);
                    } else {
                        activateMarker(newMarker);
                    }
                });
            }
            MapMarker.push(newMarker);
        });

        if (options.clustering) {
            clusteringPromise.then(cluster => cluster.addMarkers(MapMarker));

            google.maps.event.addListenerOnce(googleMap, 'idle', function() {
                clusteringPromise.then(cluster => cluster.resetViewport());
                clusteringPromise.then(cluster => cluster.redraw());
            });
        }

    }

    function removeAllMarker() {
        if (options.onDeactivateMarker) {
            MapMarker.forEach(marker => options.onDeactivateMarker(marker, api));
        }

        MapMarker.forEach((marker) => {
            marker.setMap(null);
        })

        MapMarker = [];

        if (options.clustering) {
            clusteringPromise.then(cluster => cluster.clearMarkers());
        }
    }

    function activateMarker(marker) {
        if (MapMarkeractive) {
            deactivateMarker(MapMarkeractive);
        }

        options.onActivateMarker(marker, api);
        MapMarkeractive = marker;

        setActiveMarkerStyle(marker);

        googleMap.panTo(marker.getPosition());
    }

    function deactivateMarker(marker) {
        options.onDeactivateMarker(MapMarkeractive, api);
        MapMarkeractive = null;

        setDefaultMarkerStyle(marker);
    }

    function setActiveMarkerStyle(marker) {
        if (marker.poiStyle) {
            marker.setIcon(marker.poiStyle.active);
        }
    }

    function setDefaultMarkerStyle(marker) {
        if (marker.poiStyle) {
            marker.setIcon(marker.poiStyle.default);
        }
    }

    function centerMap(){
        google.maps.event.trigger(googleMap, 'resize');
        scrollToMarkers(MapMarker, googleMap);
    }

    function scrollToMarkers (markers) {
        let latLngs = markers.map(marker => marker.getPosition());

        // Zoom/center map to fit all marker
        if (latLngs.length === 0) {
            googleMap.setCenter(options.mapOptions.center);
        } else if (latLngs.length === 1) {
            googleMap.setCenter(latLngs[0]);
        } else {
            let latLngBounds = new google.maps.LatLngBounds();
            for (let i = 0, pos; pos = latLngs[i]; i++) {
                latLngBounds.extend(pos);
            }
            googleMap.setCenter(latLngBounds.getCenter());
            googleMap.fitBounds(latLngBounds);
        }
    }

    /* Info boxes */
    // google.maps.Marker, htmlString | domNode, MapObj -> Promise(() -> InfoBox)
    function showInfoBoxByMarker(marker, content, infoBoxOptions = {}) {
        const defaultInfoBoxOptions = {
            alignBottom: true,
            pixelOffset: new google.maps.Size(-158, -50),
            boxStyle: {
                width: "300px",
                background: "#fff",
                padding: "20px"
            }
        };

        let BoxOptions = {
            ...defaultInfoBoxOptions,
            ...options.infoBoxOptions,
            ...infoBoxOptions,
            content
        };

        return import('google-maps-infobox').then(function ({InfoBox}) {
            closeInfoBoxByMarker(marker);
            marker.infoBox = new InfoBox(BoxOptions);
            google.maps.event.addListener(marker.infoBox, "closeclick", function (e) {
                deactivateMarker(marker);
            });
            marker.infoBox.open(marker.map, marker);

            return marker.infoBox;
        });
    }

    // google.maps.Marker, MapObj -> void
    function closeInfoBoxByMarker(marker) {
        if (marker.infoBox) {
            marker.infoBox.close();
            marker.infoBox = null;
        }
    }

    function getClusterByMarker(currentMarker) {
        return clusteringPromise.then(clusterObj => {
            let clusters = clusterObj.getClusters();

            return clusters.find(cluster =>
                cluster.getMarkers().find(marker => marker === currentMarker)
            );
        });
    }

    function setActiveClusterStyle(cluster) {
        if (cluster && cluster.clusterIcon_) {
            cluster.clusterIcon_.setOptions({
                ...options.clusteringOptions.active,
                styles_: options.clusteringOptions.active.styles,
                textColor_: options.clusteringOptions.active.styles.textColor,
            });
            cluster.updateIcon();
        }
    }

    function setDefaultClusterStyle(cluster) {
        if (cluster && cluster.clusterIcon_) {
            let clusterOptions = options.clusteringOptions.default || options.clusteringOptions;

            cluster.clusterIcon_.setOptions({
                ...clusterOptions,
                styles_: clusterOptions.styles,
                textColor_: clusterOptions.styles.textColor,
            });
            cluster.updateIcon();
        }
    }

    baseElement.googleMap = api;
    trigger(INITIALIZED_EVENT, baseElement);

    return api;
}

export function getApi(element) {
    if(element.googleMap) {
        return Promise.resolve(element.googleMap);
    } else {
        return new Promise(function(resolve, reject) {
            function initializeHandler() {
                resolve(element.googleMap);
                off(INITIALIZED_EVENT, initializeHandler, element);
            }
            on(INITIALIZED_EVENT, initializeHandler, element);
        });
    }
}