import {onFind} from "../libs/@elements/init-modules-in-scope";
import {init} from '../libs/@elements/alert-notification';
import objectFitImages from 'object-fit-images';
import smoothscroll from 'smoothscroll-polyfill';
require('matchmedia-polyfill');
require('matchmedia-polyfill/matchMedia.addListener');

// kick off the polyfill!
smoothscroll.polyfill();

init({
    key: '__alertNotification',
    renderFunction: function renderFunction({type, title, content, closable = true, styleModifier = '', placement, defaultRender} = {}, options) {
        if (content || title) {
            let containers = options.container;

            containers.map(function(container) {
                container.removeAttribute('hidden');
                container.insertAdjacentHTML('beforeend', (
                    `<div class="alert alert-${type} alert-dismissible fade show ${styleModifier}" role="alert">
                ${title ? (
                        `<h4 class="alert-heading">
                        ${title}
                    </h4>`
                    ) : ''}

                ${closable ? (
                        `<button type="button"
                             class="close"
                             data-bs-dismiss="alert"
                             aria-label="${options.closeText}"
                             title="${options.closeText}">
                            <span aria-hidden="true">&times;</span>
                         </button>`
                    ) : ''}
                ${content ? (
                        `<div>
                        ${content}
                    </div>`
                    ) : ''}
            </div>`));
            });
        }
    }
});

/** shared */
import {cachedFetches} from "../../shared/cached-fetches/cached-fetches";
window.cachedFetches = cachedFetches.init();

import {unhideByLoginState} from '../../shared/unhide-by-login-state/unhide-by-login-state'
unhideByLoginState()

import {setActiveRootMenu} from "../../shared/setActiveRootMenu/setActiveRootMenu";
setActiveRootMenu()

/**********************************
 *
 *      Cookies
 *
 * ********************************/

import * as cookieOverlay from '../../shared/cookie-overlay/cookieOverlay';
cookieOverlay.register();

import * as youtubeVideos from '../../shared/youtube-videos/youtubeVideos';
youtubeVideos.register();

import * as cookieInformation from '../../shared/cookie-overlay/cookieInformation'
cookieInformation.init();
window.initializeCookieElements = cookieInformation.initializeCookieElements

import * as cookieOverlaysInLightGallery from '../../shared/cookie-overlay/cookieOverlaysInLightGallery';
window.initializeCookieOverlaysInLightGallery = cookieOverlaysInLightGallery.initializeCookieOverlaysInLightGallery

objectFitImages();

import Modal from 'bootstrap/js/dist/modal';
onFind('.modal', function (element) {
    new Modal(element, {})
});

import Alert from 'bootstrap/js/dist/alert';
onFind('.alert', function (element) {
    new Alert(element)
});

import Collapse from 'bootstrap/js/dist/collapse';
onFind('.collapse', function (element) {
    new Collapse(element, {
        toggle: false
    })
});

import Tab from 'bootstrap/js/dist/tab';

import * as affixNav from './affix-nav';
affixNav.init();

import * as header from './header';
header.init();

import * as fadeIn from './fade-in';
fadeIn.init();

import * as ajaxLogin from './ajaxLogin';
ajaxLogin.init();

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as transformIn from './transform-in';
transformIn.init();

import * as toc from '../libs/@elements//toc';
toc.init();

import * as formValidation from './form-validation';
formValidation.init();

/*import * as floatingLabel from '../libs/@elements/floating-labels';
floatingLabel.init();*/

import * as actionChanger from '../libs/@elements/action-changer';
actionChanger.init();

import * as lazyImg from '../libs/@elements/lazy-img';
lazyImg.init({preventNative: true});

import * as typeahead from './typeahead';
typeahead.init();

import * as pageAlert from './page-alert';
pageAlert.init();

import * as wishlist from './wishlist';
wishlist.init();
wishlist.initInScope();

import * as cart from './cart';
cart.init();

//fallback
import * as cookieBarFallback from "./cookiePermissions";
_config.cookieServiceDefault = true;

import * as tracking from './tracking';
tracking.init();

import * as fileInput from '../libs/@elements/file-input';
fileInput.init();

import * as m8NewsletterValidation from './m8-newsletter/email-validation';
m8NewsletterValidation.init();

import * as m3ProductRow from './m3-product-row/product-row';
m3ProductRow.init();

import * as customFile from '../scripts/customFile';
customFile.init();

import * as m4ShopTheLook from './m4-shop-the-look/shop-the-look';
m4ShopTheLook.init();

import * as m6HeroStage from './m6-hero-stage/hero-stage';
m6HeroStage.init();

import * as m11ContentTeaserSlider from './m11-content-teaser/content-teaser';
m11ContentTeaserSlider.init();

import * as m22TeaserSlider from './m22-teaser-slider/teaser-slider';
m22TeaserSlider.init();

import * as m1MagazineTeaser from './m1-magazine-teaser/magazine-teaser';
m1MagazineTeaser.init();

import * as m2CategorySlider from './m2-category-slider/category-slider';
m2CategorySlider.init();

import * as m14Gallery from './m14-gallery/gallery.js';
m14Gallery.init();

import * as m17accordion from './m17-accordion-grafik/accordion';
m17accordion.init();

import * as m52SocialWallSlider from './m52-social-wall/social-wall';
m52SocialWallSlider.init();

import * as productCardSlider from './component/product-card/product-card';
productCardSlider.init();

import * as loadIcons from './loadIcons';
loadIcons.init();

import * as tabModule from '../../shared/contents/tab-module.js';
tabModule.init();

import * as tilesSplide from './tiles-splide.js';
tilesSplide.init();

import * as countryMismatchPopup from './countryMismatchPopup';
countryMismatchPopup.init();

import './raffle';

import * as wcagLinkTargetBlank from '../../shared/wcag-link-target-blank/wcagLinkTargetBlank'
wcagLinkTargetBlank.init()

import * as baseVideo from './component/base-video/base-video';
baseVideo.init();
