"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement) {
    const splideOptions = {
        type: 'slide',
        gap: 'calc(25rem/16)',
        mediaQuery: 'min',
        drag: 'free',
        arrows: false,
        pagination: false,
        autoWidth: true,
        padding: 'calc(20rem/16)',
        breakpoints: {
            768: {
                gap: 'calc(57rem/16)',
            },
            1200: {
                gap: 'calc(83rem/16)',
                padding: '0'
            },
            1921: {
                gap: 'calc(145rem/16)',
                padding: '0'
            }
        }
    }

    slider = new Splide( sliderElement, splideOptions ).mount();
}
