"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement) {
    const splideOptions = {
        type: 'slide',
        arrows: false,
        drag: true,
        mediaQuery: 'min',
        pagination: false,
        autoWidth: true,
        padding: {
            left: '1.875rem',
            right: '1.875rem'
        },
        focus: 0,
        gap: '.625rem',
        breakpoints: {
            992: {
                destroy: true
            }
        }
    }

    slider = new Splide( sliderElement, splideOptions ).mount();
}
