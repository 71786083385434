'use strict'

import {addCookieOverlayHighlightClasses} from './cookieOverlay.js'
import {showCookieInformationOverlay} from './cookieInformation.js'
import {isInitialized, setAsInitialized} from "../initializationState/initializationState";
import {createForEachElementInScopeFunc} from "../initInScope/initInScope";

export const selectors = {
    cookieOverlayButton: '.js-cookie-overlay__btn',
}

const eachContainerInScope = createForEachElementInScopeFunc(selectors.cookieOverlayButton)

export const initOverlayButtonsInScope = (scope) => {
    eachContainerInScope(scope, cookieOverlayButtonElem => {
        if (isInitialized(cookieOverlayButtonElem)) {
            return
        }
        setAsInitialized(cookieOverlayButtonElem)

        cookieOverlayButtonElem.addEventListener('click', (e) => {
            e.preventDefault();
            showCookieInformationOverlay(()=>addCookieOverlayHighlightClasses(e.target))
        })
    });
};
