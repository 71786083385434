"use strict";

import {isElementInViewport, onEnterViewPort} from '../libs/@elements/viewport-utils'
import {initAjaxFormMap as initAjaxFormMapGoogle} from "../libs/@elements/ajax-form-map/ajax-form-google";
import {findAll, findIn, addClass, removeClass, findAllIn, on, closest, removeAttribute, setAttribute} from '../libs/@elements/dom-utils';
import Collapse from 'bootstrap/js/dist/collapse';

let listItems;
let currentPosition;
export function init () {
    function getGooglePoiStyles(){
        return {
            default: {
                default: {
                    url: '/deuter_ng/static/img/pois/marker.svg',
                    size: new google.maps.Size(34, 50),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(17, 50)
                },
                active: {
                    url: '/deuter_ng/static/img/pois/marker-active.svg',
                    size: new google.maps.Size(34, 50),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(17, 50)
                }
            }
        };
    }

    findAll('.js-ajax-form-map').map(googleMap => {
        let lat = +googleMap.getAttribute('data-google-map-lat') || 47.2183939;
        let lng = +googleMap.getAttribute('data-google-map-lng') || 14.306775;

        let selectors = {
            result: '.js-ajax-form-map__result',
            loading: '.js-ajax-form-map__loading',
            notifications: '.js-ajax-form-map__notifications',
            form: '.js-ajax-form-map__form',
            additionalForm: '.js-ajax-form-map__additional-form',
            errorArea: '.js-ajax-form-map__error-area',
            retry: '.js-ajax-form-map__retry',
            link: '.js-ajax-form-map__link',
            reset: '.js-ajax-form-map__reset',
        };

        let clusteringOptions = {
            default: {
                styles: [{
                    height: 58,
                    url: "/deuter_ng/static/img/pois/marker.svg",
                    width: 34,
                    textSize: 16,
                    textColor: "#323232",
                    anchor: [8, 0]
                }]
            },
            active: {
                styles: [{
                    height: 58,
                    url: "/deuter_ng/static/img/pois/marker-active.svg",
                    width: 34,
                    textSize: 16,
                    textColor: "#323232",
                    anchor: [8, 0]
                }]
            }
        };


        let map = initAjaxFormMapGoogle(googleMap, {
            element: findIn('.js-ajax-form-map__map__canvas', googleMap),
            poiStyles: () => getGooglePoiStyles(),
            onActivateMarker: (marker, api) => onActivateMarker(googleMap, marker, api),
            onDeactivateMarker: (marker, api) => onDeactivateMarker(googleMap, marker, api),
            clustering: true,
            clusteringOptions,
            mapOptions: {
                center: {lat: lat, lng: lng},
                scrollwheel: false,
                mapTypeControl: false,
                streetViewControl: false
            },
        }, selectors);

        let locationMarker;
        onEnterViewPort(findIn('.js-ajax-form-map__map__canvas', googleMap), function () {
            listItems = _config.storesHtml;
            map.mapObj.then((mapObj) => {
                mapObj.centerMap();
                activateItemsInBounds(mapObj, googleMap);

                findAllIn('.js-google-autocomplete', googleMap).map((container) => {
                    let input = findIn('.js-google-autocomplete__input', container);
                    let resetButton = findIn('.js-google-autocomplete__reset', container);
                    let locateButton = findIn('.js-google-autocomplete__geo', container);
                    let countrySelect = findIn('.js-google-autocomplete__country', container);

                    let country = _config.lang;
                    if (countrySelect) {
                        country = countrySelect.value;
                    }
                    let autocomplete = new google.maps.places.Autocomplete(input, {
                        componentRestrictions: {country: country}
                    });

                    function setLocationMarker(initalSet) {
                        if (input.value) {
                            let url = new URLSearchParams(window.location.search);
                            url.set(input.name, input.value);
                            history.replaceState(null, null, "?" + url.toString());

                            let _geocoder = _geocoder == null ? new google.maps.Geocoder : _geocoder;
                            _geocoder.geocode({'address': input.value}, function (results, status) {
                                if (status === google.maps.GeocoderStatus.OK) {
                                    if (locationMarker) {
                                        locationMarker.setMap(null);
                                    }

                                    let position = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                                    locationMarker = new google.maps.Marker({
                                        map: mapObj.getMapInstance(),
                                        position: position,
                                        dataId: 'location_marker',
                                        zIndex: 999999
                                    });
                                    currentPosition = position;


                                    let t = setTimeout(() => {
                                        mapObj.getMapInstance().panTo(position);
                                        mapObj.getMapInstance().setZoom(11);

                                        clearTimeout(t);
                                    }, 400);
                                } else {
                                    console.error('Geocode was not successful for the following reason: ' + status);
                                }
                            });

                            if (initalSet) {
                                locationMarkerSet = true;
                            }
                        } else {
                            if (locationMarker) {
                                locationMarker.setMap(null);
                            }
                        }
                    }

                    on('change', function (evt) {
                        evt.preventDefault();

                        input.value = '';
                        removeAttribute('hidden', locateButton);
                        setAttribute('hidden', true, resetButton);

                        autocomplete.setComponentRestrictions({
                            country: countrySelect.value
                        });
                    }, countrySelect);

                    on('keydown', function (evt) {
                        if (evt.keyCode === 13) {
                            evt.preventDefault();
                            evt.stopImmediatePropagation();

                            setLocationMarker()
                        }
                    }, input);

                    google.maps.event.addListener(autocomplete, 'place_changed', function () {
                        setLocationMarker();
                    });

                    on('change', function (evt) {
                        evt.stopImmediatePropagation();

                        if (input.value) {
                            removeAttribute('hidden', resetButton);
                            setAttribute('hidden', true, locateButton);
                        } else {
                            setAttribute('hidden', true, resetButton);
                            removeAttribute('hidden', locateButton);

                            if (locationMarker) {
                                locationMarker.setMap(null);
                            }
                        }
                    }, input);

                    on('click', function (evt) {
                        evt.preventDefault();
                        input.value = '';
                        setAttribute('hidden', true, resetButton);
                        removeAttribute('hidden', locateButton);

                        if (locationMarker) {
                            locationMarker.setMap(null);
                        }
                    }, resetButton);

                    on('click', function (evt) {
                        evt.preventDefault();

                        if (navigator.geolocation) {
                            navigator.geolocation.getCurrentPosition((position) => {
                                if (locationMarker) {
                                    locationMarker.setMap(null);
                                }

                                let mapPosition = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                                locationMarker = new google.maps.Marker({
                                    map: mapObj.getMapInstance(),
                                    position: mapPosition,
                                    dataId: 'location_marker',
                                    zIndex: 99999999
                                });

                                currentPosition = mapPosition;

                                mapObj.getMapInstance().panTo(mapPosition);
                                mapObj.getMapInstance().setZoom(11);
                            }, () => {
                                console.error('geolocation not allowed')
                            });
                        } else {
                            console.error('geolocation not supported')
                        }

                    }, locateButton);

                    let mapInstance = mapObj.getMapInstance();
                    if (!locationMarker && mapObj.getMarkers().length === 0) {
                        mapInstance.addListener('center_changed', function () {
                            if (!locationMarker) {
                                setLocationMarker(true);
                            }
                        });
                    }
                });
            });
        }, {
            offset: window.innerHeight * 1.5
        });

        on('fetched.ajax-form', function (evt, result) {
            map.mapObj.then((mapObj) => {
                listItems = evt.detail.json;
                activateItemsInBounds(mapObj, googleMap);
            });
        }, googleMap);
    });
}

let markerClick = false;
let teaserClick = false;
let locationMarkerSet = false;
function onActivateMarker(googleMap, marker, mapObj) {
    if (marker.dataId) {
        let item = findIn(`.js-ajax-form-map__item[data-id="${marker.dataId}"]`, googleMap);
        if (item) {
            addClass('is-active', item);

            let collapseTarget = findIn('.collapse', item);

            let collapseInstance = Collapse.getInstance(collapseTarget);
            if (collapseInstance) {
                Collapse.getInstance(collapseTarget).show();
            } else if (collapseTarget) {
                new Collapse(collapseTarget, {
                    toggle: true
                });
            }


            let offset = matchMedia('(max-width: 767px)').matches ? 75 : 130;
            if (teaserClick !== true) {
                on('shown.bs.collapse', function () {
                    if (!isElementInViewport(item, -offset)) {
                        let list = closest('.scrolling-map__list', item);
                        if (item.getBoundingClientRect().top + item.getBoundingClientRect().height >= list.getBoundingClientRect().top + list.getBoundingClientRect().height) {
                            window.scrollTo({
                                top: item.getBoundingClientRect().top + item.getBoundingClientRect().height + window.pageYOffset - window.innerHeight,
                                behavior: 'smooth'
                            });
                        } else {
                            window.scrollTo({
                                top: item.getBoundingClientRect().top + window.pageYOffset - offset,
                                behavior: 'smooth'
                            });
                        }
                    }
                }, collapseTarget);
            }
        } else {
            addMarkerTeaser(mapObj, googleMap, marker)
        }

        teaserClick = false;
        markerClick = true;
    }
}

function onDeactivateMarker(googleMap, marker) {
    if (marker.dataId) {
        let item = findIn(`.js-ajax-form-map__item[data-id="${marker.dataId}"]`, googleMap);
        if (item) {
            removeClass('is-active', item);

            let collapseTarget = findIn('.collapse', item);
            let collapseInstance = Collapse.getInstance(collapseTarget);
            if (collapseInstance) {
                Collapse.getInstance(collapseTarget).hide();
            }
        }

        markerClick = false;
    }
}

function initMapItem(mapObj, googleMap, item) {
    const initItem = () => {
        teaserClick = true;
        let currentActiveMarker = mapObj.getActiveMarker();
        if (currentActiveMarker) {
            mapObj.getClusterByMarker(currentActiveMarker).then((cluster) => {
                mapObj.setDefaultClusterStyle(cluster);
            });
        }

        let id = item.getAttribute('data-id');
        let activeMarker = mapObj.getMarkers().filter(marker =>
            !!marker.dataId && marker.dataId.toString() === id.toString())[0];

        if (activeMarker) {
            mapObj.activateMarker(activeMarker);
            mapObj.getClusterByMarker(activeMarker).then((cluster) => {
                mapObj.setActiveClusterStyle(cluster);
            });

            let position = new google.maps.LatLng(activeMarker.position.lat(), activeMarker.position.lng());
            mapObj.getMapInstance().panTo(position);
        }

        if (matchMedia('(max-width: 767px)').matches) {
            window.scrollTo({
                top: googleMap.getBoundingClientRect().top + window.pageYOffset - 75,
                behavior: 'smooth'
            });
        }
    };

    findAllIn('.js-ajax-form-map__show', item).map((button) => {
        button.addEventListener('click', initItem);

        return {
            cleanUp: () => {
                button.addEventListener('click', initItem);
            }
        };
    });
}

function debounce(fn, time) {
    let timeout;
    return function() {
        const args = arguments;
        const functionCall = () => fn.apply(this, args);
        clearTimeout(timeout);
        timeout = setTimeout(functionCall, time);
    }
}

function activateItemsInBounds(mapObj, googleMap) {
    let mapInstance = mapObj.getMapInstance();
    mapInstance.addListener('bounds_changed', debounce(() => {
        //ignore bounds_changed on inital location marker
        if (locationMarkerSet === true) {
            locationMarkerSet = false;
            return;
        }

        //ignore bounds_changed on activate marker
        if (markerClick === true) {
            markerClick = false;
            return;
        }

        let list = findIn('.scrolling-map__list', googleMap);

        const mapBounds = mapInstance.getBounds();
        let poisInBounds = [];
        let poisNotInBounds = [];
        let foundMarkers = [];
        for(let i = 0; i < mapObj.getMarkers().length; i++){
            if(mapBounds.contains(mapObj.getMarkers()[i].getPosition())){
                foundMarkers.push(mapObj.getMarkers()[i])
                poisInBounds.push(mapObj.getMarkers()[i].dataId)
            }else {
                poisNotInBounds.push(mapObj.getMarkers()[i].dataId)
            }
        }


        if (currentPosition) {
            poisInBounds = [];
            foundMarkers.sort(sortByDistance);

            for(let i = 0; i < foundMarkers.length; i++){
                poisInBounds.push(foundMarkers[i].dataId)
            }
        }


        if(poisInBounds.length < 75){
            for(let i = 0; i < poisInBounds.length; i++) {
                let html = listItems[poisInBounds[i]];
                if(!findIn('.js-ajax-form-map__item[data-id="'+ poisInBounds[i] +'"]', list) && html){
                    list.insertAdjacentHTML('beforeend', (html));

                    initMapItem(mapObj, googleMap, findIn('.js-ajax-form-map__item[data-id="'+ poisInBounds[i] +'"]', list));
                }
            }

            for(let i = 0; i < poisNotInBounds.length; i++) {
                if(findIn('.js-ajax-form-map__item[data-id="'+ poisNotInBounds[i] +'"]', list)){
                    list.removeChild(findIn('.js-ajax-form-map__item[data-id="'+ poisNotInBounds[i] +'"]', list));
                }
            }

            if (matchMedia('(min-width: 768px)').matches) {
                window.scrollTo({
                    top: list.getBoundingClientRect().top + window.pageYOffset - 103
                });
            }

            if (findIn('.js-ajax-form-map__result-info', googleMap)) {
                if (poisInBounds.length > 0) {
                    setAttribute('hidden', true, findIn('.js-ajax-form-map__result-info', googleMap));
                } else {
                    removeAttribute('hidden', findIn('.js-ajax-form-map__result-info', googleMap));
                }
            }
        }
    }, 250));
}

function addMarkerTeaser(mapObj, googleMap, marker) {
    let list = findIn('.scrolling-map__list', googleMap);
    let html = listItems[marker.dataId];
    if(!findIn('.js-ajax-form-map__item[data-id="'+ marker.dataId +'"]', list) && html){
        list.insertAdjacentHTML('beforeend', (html));
        initMapItem(mapObj, googleMap, findIn('.js-ajax-form-map__item[data-id="'+ marker.dataId +'"]', list));
        onActivateMarker(googleMap, marker, mapObj)
    }
}



function calculateDistance(lat1, lon1, lat2, lon2) {
    var R = 6371.0710; // Radius of the Earth in kilometers
    var rlat1 = lat1 * (Math.PI/180); // Convert degrees to radians
    var rlat2 = lat2 * (Math.PI/180); // Convert degrees to radians
    var difflat = rlat2-rlat1; // Radian difference (latitudes)
    var difflon = (lon2 - lon1) * (Math.PI/180); // Radian difference (longitudes)

    return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
}

function sortByDistance(a, b) {
    const positionA = a.getPosition();
    const positionB = b.getPosition();
    let distanceToA = calculateDistance(positionA.lat(), positionA.lng(), currentPosition.lat(), currentPosition.lng());
    let distanceToB = calculateDistance(positionB.lat(), positionB.lng(), currentPosition.lat(), currentPosition.lng());

    if (distanceToA < distanceToB) {
        return -1;
    }
    if (distanceToA > distanceToB) {
        return 1;
    }

    return 0;
}