"use strict";

import {findIn, findAllIn, addClass, on, removeClass, removeAttribute, setAttribute, hasClass} from '../libs/@elements/dom-utils';
import {onFind} from "../libs/@elements/init-modules-in-scope";

export function init () {
    onFind('.js-scrolling-area', function (container) {
        let prevButton = findAllIn('.js-scrolling-area__prev', container),
            nextButton = findAllIn('.js-scrolling-area__next', container),
            dots = findAllIn('.js-scrolling-area__dot', container),
            scrollArea = findIn('.js-scrolling-area__area', container);

        let items = findAllIn('.js-scrolling-area__item', container);
        const scrollWidth = getScrollWidth(items[0]);
        const offset = Math.round(items[0].getBoundingClientRect().left);

        //initial scroll position 0
        scrollArea.scrollLeft = 0;

        nextButton.map((button) => {
            on('click', function () {
                scrollTo(scrollArea, scrollArea.scrollLeft + scrollWidth)
            }, button);
        });

        prevButton.map((button) => {
            if(scrollArea.scrollLeft === 0) {
                setAttribute('disabled', true, button);
            } else {
                removeAttribute('disabled', button);
            }

            on('click', function () {
                scrollTo(scrollArea, scrollArea.scrollLeft - scrollWidth)
            }, button);
        });

        //extra for history slider
        if (hasClass('js-history-slider__area', scrollArea)) {
            items.map((item) => {
                on('click', function () {
                    if (!hasClass('is-active', item)) {
                        if (Math.round(item.getBoundingClientRect().left) < Math.round(scrollArea.getBoundingClientRect().width / 2)) {
                            scrollTo(scrollArea, scrollArea.scrollLeft - scrollWidth / 1.5)
                        } else {
                            scrollTo(scrollArea, scrollArea.scrollLeft + scrollWidth)
                        }
                    }
                }, item);
            });
        }

        if (dots) {
            dots.map((button) => {
                on('click', function () {
                    let id = button.dataset.scrollingAreaId;
                    scrollTo(scrollArea, Math.round(findIn('.js-scrolling-area__item[data-scrolling-area-id="'+ id +'"]', container).getBoundingClientRect().left) + scrollArea.scrollLeft - Math.round(scrollArea.getBoundingClientRect().left))
                }, button);
            });
        }

        let direction = scrollArea.scrollLeft;
        scrollArea.addEventListener('scroll', function (evt) {

                if (dots) {
                    dots.map((item) => removeClass('is-active', item));
                }
                nextButton.map((button) => removeAttribute('disabled', button));

                if(scrollArea.scrollLeft === 0) {
                    prevButton.map((button) => setAttribute('disabled', true, button));
                } else {
                    prevButton.map((button) => removeAttribute('disabled', button));
                }

                items.map((item, index) => {
                    removeClass('is-active', item);
                    let itemLeft = Math.round(item.getBoundingClientRect().left + 2);

                    if (direction < scrollArea.scrollLeft) {
                        if (itemLeft < offset + scrollWidth && itemLeft >= offset) {
                            addClass('is-active', item);

                            let id = item.dataset.scrollingAreaId;
                            if (dots.length > 0) {
                                addClass('is-active', findIn('.js-scrolling-area__dot[data-scrolling-area-id="'+ id +'"]', container));
                            }

                            if (items.slice(-1)[0] === item) {
                                nextButton.map((button) => setAttribute('disabled', true, button));
                            }

                        } else if (items.slice(-1)[0].getBoundingClientRect().right <= document.documentElement.clientWidth) {
                            addClass('is-active', items.slice(-1)[0]);
                        }
                    } else {
                        if (itemLeft + scrollWidth > offset &&
                            itemLeft <= scrollWidth &&
                            itemLeft > 0) {
                            addClass('is-active', item);

                            let id = item.dataset.scrollingAreaId;
                            if (dots.length > 0) {
                                addClass('is-active', findIn('.js-scrolling-area__dot[data-scrolling-area-id="'+ id +'"]', container));
                            }
                        }
                    }
                });


            direction = scrollArea.scrollLeft;

        }, false);

        updateSliderArrows(prevButton, nextButton, container);

        updateScrollWidth(items[0]);
    });
}

export function scrollTo(scrollArea, scroll) {
    try {
        scrollArea.scroll({
            left: scroll,
            behavior: 'smooth'
        });
    } catch (e) {
        scrollArea.scrollLeft = scroll;
    }
}

export function getScrollWidth(item) {
    return Math.round(item.getBoundingClientRect().width);
}

function updateScrollWidth(item) {
    let delay = 250,
        throttled = false;

    window.addEventListener('resize', function() {
        if (!throttled) {
            getScrollWidth(item);
            throttled = true;
            setTimeout(function() {
                throttled = false;
            }, delay);
        }
    });
}

function updateSliderArrows(prevButton, nextButton, container) {
    let activeSlide = findIn('.scrolling-area__item.is-active picture', container);

    if(!activeSlide) {
        return;
    }

    let activeSlideHeight = activeSlide.offsetHeight,
        breakpoint = 1800;

    prevButton[0].style.height = `${activeSlideHeight}px`;
    nextButton[0].style.height = `${activeSlideHeight}px`;

    window.addEventListener('resize', (ev) => {
        let currentSlideHeight = activeSlide.offsetHeight;
        if(window.innerWidth <= breakpoint) {
            prevButton[0].style.height = null;
            nextButton[0].style.height = null;
        } else {
            prevButton[0].style.height = `${currentSlideHeight}px`;
            nextButton[0].style.height = `${currentSlideHeight}px`;
        }
    }, false)
}