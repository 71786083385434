"use strict";

import Pristine from "../../libs/pristine/pristine.js";
import {onFind} from "../../libs/@elements/init-modules-in-scope";
import {findIn, toggleClass} from "../../libs/@elements/dom-utils";
import throwError from "../../libs/@elements/throw-error"

const Selectors = {
    FormEl: '.mai__m8-newsletter__form',
    EmailInput: '.mai__m8-newsletter__emailinput',
    SubmitBtn: '.mai__m8-newsletter__submitbtn',
    OverlayFade: '.mai__m8-newsletter__overlayfade'
}

const States = {
    HasLongerInput: 'mai__m8-newsletter__form--show-overlay'
}

export function init () {

    const defaultConfig = {
        classTo: 'form-group',
        errorClass: 'has-danger',
        successClass: 'has-success',
        errorTextParent: 'form-group',
        errorTextTag: 'div',
        errorTextClass: 'text-help'
    };

    let formEl,
        emailInput,
        submitBtn,
        overlayFade,
        pristine;

    onFind(Selectors.FormEl, (element) => {
        formEl = element;
        emailInput = findIn(Selectors.EmailInput, element) ? findIn(Selectors.EmailInput, element)  : throwError("M8 Mail Validation JS – Input missing");
        submitBtn = findIn(Selectors.SubmitBtn, element) ? findIn(Selectors.SubmitBtn, element) : throwError("M8 Mail Validation JS – Submit missing");
        overlayFade = findIn(Selectors.OverlayFade, element) ? findIn(Selectors.OverlayFade, element) :  throwError("M8 Mail Validation JS – Overlay missing");
        pristine = new Pristine(element, defaultConfig);

        // Disable Submit Button when JS is active in Browser - prevent unusable form for this rare case
        submitBtn.disabled = true;

        emailInput.addEventListener('keyup', validateEmailInput, false);
        emailInput.addEventListener('keyup', count, false);
        emailInput.addEventListener('paste', validateEmailInput, false);
        emailInput.addEventListener('paste', count, false);

        // Timeout was set for updating status immediately after pasting
        function validateEmailInput() {
            setTimeout(() => {
                var valid = pristine.validate();

                if(valid === true) {
                    submitBtn.disabled = false;
                } else {
                    submitBtn.disabled = true;
                }
            },0)
        };

        function count() {
            if (!emailInput || emailInput.value === undefined) {
                return;
            }

            const total = emailInput.value.replace(/\s/g, '');
            element.classList.toggle(States.HasLongerInput, total.length >= 28);
        }
    })
}
