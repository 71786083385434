"use strict";

import {registerOnConsentChangedAction} from '../cookie-overlay/cookieInformation'
import {isInitialized, setAsInitialized} from "../initializationState/initializationState";
import {playVideo} from "../video-control/videoControl";
import {tryNTimes} from "../tryNTimes/tryNTimes";
import {createForEachElementInScopeFunc} from "../initInScope/initInScope";

//keep in sync with the twig template
const REQUIRED_COOKIES = ['cookie_cat_marketing']

const eachContainerInScope = createForEachElementInScopeFunc('.js-video_youtube')

export function register() {
    registerOnConsentChangedAction(REQUIRED_COOKIES, tryInitVideos)
}

export function tryInitVideos(scope) {
    eachContainerInScope(scope, youtubeVideo => {
        if (isInitialized(youtubeVideo)) {
            return
        }
        setAsInitialized(youtubeVideo)

        const
            //it can take a while until the iframe is rendered
            findVideo = async (onlyVisible) => tryNTimes(() => {
                function isVisible(e) {
                    return !!( e.offsetWidth || e.offsetHeight || e.getClientRects().length );
                }

                const vid = youtubeVideo.querySelector(':scope iframe')

                if (!vid || (onlyVisible && !isVisible(vid))) {
                    throw new Error('no video found (yet) or element is invisible')
                }
                return vid
            }),
            playButton = youtubeVideo.querySelector(':scope .js-video__playbtn'),
            autoPlay = youtubeVideo.dataset.autoplay,
            thumbnail = youtubeVideo.querySelector(':scope .js-video_youtube-container-thumbnail'),
            buttons = youtubeVideo.querySelector(':scope .js-video_youtube-container-buttons')

        const play = () => {
            findVideo().then(video => {
                playVideo(video)

                setTimeout(() => {
                    youtubeVideo.classList.add('is-playing')
                    thumbnail.classList.add('d-md-none')
                    buttons.classList.add('d-md-none')
                }, 50)
            }, ()=>{})
        };

        const autoplay = () => {
            findVideo(true).then(video => video.addEventListener('load', play), () => {})
        }

        if (autoPlay) {
            autoplay()
        } else if (playButton) {
            playButton.addEventListener('click', () => play());
        }
    })
}
