'use strict'

import zxcvbn from 'zxcvbn/dist/zxcvbn'
import {find} from "../libs/@elements/dom-utils";

export function init() {
    let password = find('.js-pw-check__input');
    let progress = find('.js-pw-check__progress');
    if(password) {
        password.addEventListener("input", function() {
            let val = password.value;
            let result = zxcvbn(val).score;

            switch (result) {
                case 0:
                    progress.style.width = "2%";
                    progress.style.background = "#dc3545";
                    break;

                case 1:
                    progress.style.width = "25%";
                    progress.style.background = "#dc3545";
                    break;

                case 2:
                    progress.style.width = "50%";
                    progress.style.background = "#ffc107";
                    break;

                case 3:
                    progress.style.width = "75%";
                    progress.style.background = "#ffc107";
                    break;

                case 4:
                    progress.style.width = "100%";
                    progress.style.background = "#28a745";
                    break;
            }
        });
    }
}
