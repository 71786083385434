'use strict'
import $ from 'jquery';
import throwError from "../libs/@elements/throw-error"
import fetch from "../libs/@elements/fetch"
import 'url-search-params-polyfill'; // Edge Polyfill
import {showNotification} from "../libs/@elements/alert-notification";

let url;

export function initInScope($scope) {
    const
        $alerts  = $scope.find('.js-global-alert');

    url = _config.globalAlertDismissRoute ? _config.globalAlertDismissRoute : throwError("no url in config found for global error dismiss header message");

    $alerts.each((_,el) => {
        initAlert($(el));
    })
}

export function initAlert($alert){
    if(!($alert instanceof $)){
        throwError("alert not instance of jquery")
        return;
    }

    let alert = document.getElementById($alert.attr('id'));
    let pendingRequest;

    alert.addEventListener('close.bs.alert', (e) => {
        e.preventDefault();

        if (pendingRequest) {
            pendingRequest.abort();
            pendingRequest = null;
        }
        pendingRequest = fetch(url);
        pendingRequest.then((res) => {
            return res.clone().json()
        }).then((res) => {
            if(res.success){
                $alert.remove();
            }
        })
        showNotification(pendingRequest);

    })

}