"use strict";

import {onEnterViewPort} from "../libs/@elements/viewport-utils";
import {onFind} from "../libs/@elements/init-modules-in-scope";
import loadScript from '../libs/@elements/load-script';
import {findIn, removeAttribute, setAttribute} from '../libs/@elements/dom-utils';
import fetch from '../libs/@elements/fetch';

export function init() {
    onFind('.js-squarelovin', function (container) {
        if (container.getAttribute('data-url')) {
            let pendingRequest = fetch(container.getAttribute('data-url'));
            let loadingSpinner = findIn('.js-squarelovin__loading', container);

            removeAttribute('hidden', loadingSpinner);

            pendingRequest.then((res) => {
                return res.clone().json()
            }).then((res) => {
                if (res) {
                    setAttribute('hidden', true, loadingSpinner);

                    res.map((item) => {
                        const altMatch = item.posting_text.match(/[^!?.]+[!?.\n]+/gi)
                        const altText = altMatch ? altMatch[0] : 'Squarelovin Image'
                        let itemHtml = '<div class="squarelovin__item"><div class="stretch-link position-relative">' +
                            '<div class="ratio ratio-1x1 squarelovin__item-img">' +
                            '<img src="'+ item.image +'" class="ratio-item" alt="'+altText+'">' +
                            '</div>' +
                            '<div class="squarelovin__item-content"><div class="squarelovin__item-caption"><a target="_blank" href="'+ item.link +'" class="stretch-link__link">'+ item.posting_text +'</a></div></div>' +
                            '</div></div>';

                        findIn('.js-squarelovin__result', container).insertAdjacentHTML('beforeend', itemHtml);
                    });
                }
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    /*Do error stuff*/
                    console.error(error);
                }
            });
        } else {
            const id = container.getAttribute('data-squarelovin-id');
            const type = container.getAttribute('data-squarelovin-type') || 'teaser';
            const pid = container.getAttribute('data-squarelovin-pid');
            const dataStream = container.getAttribute('data-squarelovin-stream') || '5909ae123ee0733012a39f13';

            let pidTemplate = '';
            if(pid) {
                pidTemplate = '&pid=' + pid;
            }

            onEnterViewPort(container, () => {
                container.innerHTML =  '<div id="' + id + '" datastream="' + dataStream + '"></div>';
                loadScript('https://squarelovin.com/squarelovin.js?stream=' + dataStream + '&t=' + type + pidTemplate + '&l=' + _config.lang + '&u=' + location.href + '').then(() => {}).catch(console.error);
            }, {
                offset: window.innerHeight / 2
            })
        }
    });
}