"use strict";

import $ from 'jquery';

export function initInScope ($scope) {
    $scope.find('.js-floating-label').each(function(){
        let $this = $(this);
        let $formGroup = $(this).closest('.form-group');

        if($this.val() !== null && $this.val().length > 0 || $this.attr('placeholder') !== undefined && $this.attr('placeholder').length > 0) {
            $this.addClass('has-value');
            $formGroup.addClass('has-value');
        }

        // Look for changes in the value
        $this.on("propertychange change keyup input paste", function(){
            if($this.val().length === 0 && ($this.attr('placeholder') === undefined || $this.attr('placeholder').length === 0)) {
                $this.removeClass('has-value');
                $formGroup.removeClass('has-value');
            } else {
                $this.addClass('has-value');
                $formGroup.addClass('has-value');
            }
        });
    });
}