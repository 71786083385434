"use strict";

import {onFind} from "../libs/@elements/init-modules-in-scope";
import {getConfigValue} from '../libs/@elements/config-utils';
import Pristine from "../libs/pristine/pristine.js";
import {getLoadingContainer} from "./loading-spinner";

export const defaultOptions = {
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'form-errors invalid-feedback'
};

const defaultSelectors = {
    base: '.js-form-validation'
};

let pristineForms = [];

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createFormValidation(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createFormValidation(form, options = defaultOptions) {
    if (getConfigValue('lang')) {
        Pristine.setLocale(getConfigValue('lang'))
    }

    const pristine = new Pristine(form, options);

    form.addEventListener('submit', function (e) {
        if (!pristine.validate()){
            e.preventDefault();
            e.stopImmediatePropagation();
        } else {
            const formBuilderButtonEl = form.getElementsByClassName('js-submit-form__btn');
            if (formBuilderButtonEl.length) {
                addLoadingIndicator(formBuilderButtonEl)
            }
            const submitBtn = form.querySelectorAll('button[type=submit]');
            if (submitBtn.length) {
                addLoadingIndicator(submitBtn)
            }
        }
    });

    pristineForms.push(pristine);
    return pristine;
}

export function updatePristine(form) {
    let pristine = pristineForms.filter((object) => {
        return object.form === form;
    });

    pristine[0].reset(); // resets errors
    pristine[0].destroy(); // clean up

    createFormValidation(form)
}

export function getPristineForm(index) {
    return pristineForms[index] || null;
}

function addLoadingIndicator(el) {
    for (let i = 0; i < el.length; i++) {
        const button = el[i];
        if (button.style.position !== 'absolute' || 'relative') {
            button.style.position = 'relative';
        }
        button.setAttribute('disabled', true);
        button.innerHTML += getLoadingContainer();
    }

    //setTimeout(() => {
    //    button.removeAttribute('disabled');
    //    const loadingContainer = button.getElementsByClassName('loading-overlay')
    //    button.removeChild(loadingContainer[0])
    //}, 5000)
}