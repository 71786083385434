"use strict";

import {add} from '../libs/@elements/scroll-animations';
import {findAll} from "../libs/@elements/dom-utils";

export function init() {
    add(
        findAll('.js-transform-in'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

// gets element -> returns number between 100 and 0
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 8 * 7;
    const end = window.innerHeight / 2;

    return {
        opacity: 1 - Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),1),0),
        transform: matchMedia('(max-width: 767px)').matches ? Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end), 1),0) * 40 : Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end), 1),0) * 90
    };
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    element.style.opacity = progress.opacity;

    if (matchMedia('(min-width: 768px)').matches) {
        switch (element.getAttribute('data-transform-start')) {
            case 'right':
                element.style.transform = 'translate3d('+ progress.transform +'px, 0, 0)';
                break;
            case 'left':
                element.style.transform = 'translate3d(-'+ progress.transform +'px, 0, 0)';
                break;
            default:
                element.style.transform = 'translate3d(0,-'+ progress.transform +'px, 0)';
        }
    }
}