"use strict";

import Splide from '@splidejs/splide';

const splideOptions = {
    type: 'loop',
    perMove: 1,
    perPage: 3,
    gap: '1.25rem',
    pagination: false,
}

export function init (sliderElement) {
    return new Splide( sliderElement, splideOptions ).mount();
}
