function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function tryNTimes(func, times = 100, interval = 50) {
    let attemptCount = 0
    while (true) {
        attemptCount += 1
        try {
            return await func();
        } catch (error) {
            if (attemptCount >= times) {
                throw new Error('out of tries: ' + error.message);
            }
        }
        await delay(interval)
    }
}