"use strict";

import Splide from '@splidejs/splide';

const splideOptions = {
    type: 'slide',
    padding: {
        left: '2.1875rem',
        right: '2.1875rem'
    },
    perPage: 2,
    perMove: 1,
    focus: 'center',
    gap: '1.125rem',
    arrows: false,
    pagination: false,
    mediaQuery: 'min',
    drag: true,
    breakpoints: {
        768: {
            perPage: 3,
            perMove: 1,
            focus: 'center',
            drag: true,
            padding: {
                left: '2.1875rem',
                right: '2.1875rem'
            },
        },
        992: {
            perPage: 1,
            padding: false,
            drag: false
        }
    }
}

export function init (sliderElement) {
    return new Splide( sliderElement, splideOptions ).mount();
}
