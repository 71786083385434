"use strict";

import {default as throwError, throwWarning} from "../../../libs/@elements/throw-error"
import {onFind} from "../../../libs/@elements/init-modules-in-scope";
import {findIn} from "../../../libs/@elements/dom-utils";

const Selectors = {
    Wrapper: '.mai__base-video--wrapper.asset-video',
    ImageWrapper: '.mai__base-video__video-poster-wrapper',
    PlayBtn: '.mai__base-video__play-btn',
    PauseBtn: '.mai__base-video__pause-btn',
    Video: '.mai__base-video__video'
}
const States = {
    ImageHidden: 'd-none',
    IsPlaying: 'mai__base-video--is-playing',
};

export function init () {
    onFind(Selectors.Wrapper, (element) => {
        let containerEl;
        let imageWrapperEl;
        let playBtnEl;
        let pauseBtnEl;
        let videoEl;

        containerEl = element ? element : throwError("AS Video JS – Container missing");
        imageWrapperEl = findIn(Selectors.ImageWrapper, containerEl);
        playBtnEl = findIn(Selectors.PlayBtn, containerEl);
        pauseBtnEl = findIn(Selectors.PauseBtn, containerEl);
        videoEl = findIn(Selectors.Video, containerEl) ? findIn(Selectors.Video, containerEl) : throwWarning("AS Video JS - Video Element missing");
        if (!videoEl) return;
        if (!(videoEl instanceof HTMLVideoElement)) return;

        if (playBtnEl) {
            playBtnEl.addEventListener('click', () => {
                if(imageWrapperEl) {
                    imageWrapperEl.classList.add(States.ImageHidden);
                }
                videoEl.play();
                containerEl.classList.add(States.IsPlaying);
            });
        }

        if(pauseBtnEl) {
            pauseBtnEl.addEventListener('click', () => {
                videoEl.pause();
                containerEl.classList.remove(States.IsPlaying);
            });
        }
    })
}
