"use strict";

let scrollLock = false;

export function activateScrollLock () {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    scrollLock = true;
}
export function deactivateScrollLock () {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    scrollLock = false;
}
export function isScrollLock() {
    return scrollLock;
}