import $ from 'jquery';
import './scripts/common';
import {find} from './libs/@elements/dom-utils';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as scrollingArea from './scripts/scrolling-area';
scrollingArea.init();

import * as historySlider from './scripts/history-slider';
historySlider.init();

import * as ajaxForm from './libs/@elements/ajax-form';
if (find('.js-ejb-ajax-form')) {
    let formApi = ajaxForm.createAjaxForm(find('.js-ejb-ajax-form'), {}, {
        base: '.js-ejb-ajax-form',
        result: '.js-ejb-ajax-form__result',
        loading: '.js-ejb-ajax-form__loading',
        notifications: '.js-ejb-ajax-form__notifications',
        form: '.js-ejb-ajax-form__form',
        additionalForm: '.js-ejb-ajax-form__additional-form',
        errorArea: '.js-ejb-ajax-form__error-area',
        retry: '.js-ejb-ajax-form__retry',
        link: '.js-ejb-ajax-form__link',
        reset: '.js-ejb-ajax-form__reset',
    });
}

import * as video from './scripts/video';
video.init();

import * as bottleAnimation from './scripts/bottle-animation';
bottleAnimation.init();

import * as collapseRow from './scripts/collapse-row';
collapseRow.init();

import * as smoothScroll from './scripts/smooth-scroll';
smoothScroll.init({
    scrollOffset: matchMedia('(min-width: 768px)').matches ? 100 : 70
});

import * as friendlyCaptchaV1 from './scripts/friendlyCaptchaV1';
friendlyCaptchaV1.init();

import * as pwCheck from './scripts/pw-check';
pwCheck.init();

import * as squarelovin from './scripts/squarelovin';
squarelovin.init();

import * as ajaxFormMap from './scripts/ajax-form-map';
ajaxFormMap.init();

import * as dropdownMenu from './scripts/component/in-page-menu/dropdownMenu';
dropdownMenu.init();


import * as floatingLabel from './libs/@elements/floating-labels';
app.floatingLabel = floatingLabel.init;
app.floatingLabel();

import * as langModal from './scripts/langModal';
langModal.init();

import * as affix from './libs/@elements/affix';
affix.init({mediaQuery: '(min-width: 768px)'});

import * as globalAlert from './scripts/globalAlert';
globalAlert.initInScope($('body'));

import * as friendlycaptchaOverlay from './scripts/component/friendlycaptcha-overlay/friendlycaptcha-overlay';
friendlycaptchaOverlay.init();
