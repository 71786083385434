"use strict";
import {onFind} from "../libs/@elements/init-modules-in-scope";
import {on, findIn, addClass, removeClass} from "../libs/@elements/dom-utils";
import {updateNavOffset} from "./affix-nav";

export function init() {
    onFind('.js-page-alert', function (baseElement) {
        if (!sessionStorage.getItem('pager-alert-dismissed')) {
            addClass('is-active', baseElement);
            updateNavOffset();
        }

        const closeBtn = findIn('.js-page-alert__close', baseElement)

        if(closeBtn) {
            on('click', function () {
                sessionStorage.setItem('pager-alert-dismissed', true);

                removeClass('is-active', baseElement);
                updateNavOffset();
            }, closeBtn);
        }
    });
}