"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement) {
    const splideOptions = {
        type: 'slide',
        perPage: 1,
        direction: 'ttb',
        mediaQuery: 'min',
        drag: false,
        arrows: true,
        pagination: false,
        autoWidth: false,
        gap: 0,
        padding: 0,
        height: ' var(--flyout-height-xl)',
        breakpoints: {
            0: {
                destroy: 'completely'
            },
            1200: {
                height: ' var(--flyout-height-xl)',
            },
            1921: {
                height: ' var(--flyout-height-xxxl)',
            }

        }
    };

    slider = new Splide( sliderElement, splideOptions ).mount();
    // hide arrows if there is only one element
    if(slider.length <= 1) {
        const arrows = sliderElement.querySelector('.splide__arrows');
        if(arrows) {
            arrows.classList.add('mai__slider-arrows--d-none');
        }
    }
}
