'use strict'

import Cookies from "js-cookie";
import {tryNTimes} from "../tryNTimes/tryNTimes";
import {removeCookieOverlayHighlightClasses} from './cookieOverlay.js'

export const COOKIE_INFORMATION_CONSENT_GIVEN_EVENTNAME = 'CookieInformationConsentGiven'

const selectors = {
    cookieInformationOverlay: '#coiOverlay'
}
// From https://policy.app.cookieinformation.com/9e5f1e/deuter.com/de.js
export const COOKIE_INFORMATION_CATEGORIES = [
        "cookie_cat_necessary",
        "cookie_cat_functional",
        "cookie_cat_statistic",
        "cookie_cat_marketing",
        "cookie_cat_unclassified",
    ];
const COOKIE_NAME = 'CookieInformationConsent'

let cookieInformationOverlay = ''
let approvedCookies
let onConsentChangedActions = new Set()
let onNewElementsActions = new Set()

//keep track of cookies required on the current site
const allRequiredCookies = new Set()

//initialize after the dependentActions have been added
export function init() {
    function initCookies() {
        approvedCookies = readCookieConsents()
    }

    initCookies()

    window.addEventListener(COOKIE_INFORMATION_CONSENT_GIVEN_EVENTNAME, onCookiesSubmitted)

    initializeCookieElements()
}

async function onCookiesSubmitted() {
    function relevantConsentsHaveBeenRevoked(newCookies) {
        function sets_intersect(set1, set2) {
            return new Set([...set1].filter(i => set2.has(i)))
        }

        function sets_containAll(set, subset) {
            return [...subset].every((x) => set.has(x))
        }

        const relevantConsentedBeforeUpdate = sets_intersect(approvedCookies, allRequiredCookies)

        return !sets_containAll(newCookies, relevantConsentedBeforeUpdate)
    }

    removeCookieOverlayHighlightClasses();
    
    const newCookies = readCookieConsents()

    //if consents to already-initialized cookie-dependent contents have been revoked, reload the page
    if (relevantConsentsHaveBeenRevoked(newCookies)) {
        location.reload()
        return
    }

    approvedCookies = newCookies

    //initialized newly consented cookie-dependent contents
    await tryInitializeCookieDependentContents(document.body)
}

async function tryInitializeCookieDependentContents(scope) {
    for (const action of onConsentChangedActions) {
        if (checkAllRequiredCookies(action.requiredCookies)) {
            if (action.await) {
                await action.action(scope)
            } else {
                action.action(scope);
            }
        }
    }
}

const readCookieConsents = () => {
    const noConsentsGiven = new Set()
    const cookies = Cookies.get(COOKIE_NAME)
    if (!cookies) {
        console.warn(`1669725663 – cookieOverlay.js no ${COOKIE_NAME} cookie found.`);
        return noConsentsGiven
    }

    const cookiesParsed = JSON.parse(Cookies.get(COOKIE_NAME))
    if (!cookiesParsed.consents_approved) {
        console.warn('1669731738 – cookieInformation.js missing cookieInformation approved cookies array')
    } else {
        return new Set(cookiesParsed.consents_approved)
    }

    return noConsentsGiven
}

export async function showCookieInformationOverlay(callback) {
    await tryNTimes(() => {
        cookieInformationOverlay = document.querySelector(selectors.cookieInformationOverlay) ?? false;
        if (!cookieInformationOverlay) {
            throw new Error('1669725663 – cookieInformation.js missing cookieInformation overlay element')
        }
        window.CookieConsent.renew();
        callback && callback();
    }, 300)
}

export function checkAllRequiredCookies(requiredCookies) {
    if (!approvedCookies) {
        return false
    }

    for (const cookie of requiredCookies) {
        if (!approvedCookies.has(cookie)) {
            return false;
        }
    }

    return true
}

export const addToAllRequiredCookies = (requiredCookies) => {
    for (const cookie of requiredCookies) {
        allRequiredCookies.add(cookie)
    }
}

export const registerOnNewElementsAction = (initAction) => {
    onNewElementsActions.add(initAction)
}

/**
 * call this function if new cookie dependend elements have been added eg. per ajax or lightgallery
 */
export function initializeCookieElements(scope = document.body) {
    for (const onNewElementsAction of onNewElementsActions) {
        onNewElementsAction(scope)
    }

    tryInitializeCookieDependentContents(scope).then()
}

export const registerOnConsentChangedAction = (requiredCookies, action, await_ = false) => {
    onConsentChangedActions.add({
        action: action,
        requiredCookies: requiredCookies,
        await: await_
    })

    for (const cookie of requiredCookies) {
        allRequiredCookies.add(cookie)
    }
}
