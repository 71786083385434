"use strict";

import {findAll, on, addClass, removeClass, findIn, setAttribute, removeAttribute} from "../libs/@elements/dom-utils";

export function init () {
    let videos = findAll('.js-video');//only asset videos

    videos.forEach((videoContainer) => {
        let video = findIn('.js-video__video', videoContainer),
            playButton = findIn('.js-video__play', videoContainer),
            videoLoading = findIn('.js-video__loading', videoContainer),
            posterElement = findIn('.js-video__poster', videoContainer);

        if (videoContainer.getAttribute('data-video-url')) {
            const loadVideo = () => {
                removeAttribute('hidden', videoLoading);
                video.src = videoContainer.getAttribute('data-video-url');

                video.onload = function (){
                    addClass('is-playing', videoContainer);
                    setAttribute('hidden', true, videoLoading);
                }
            };

            on('click', () => loadVideo(), playButton);

            if (posterElement) {
                on('click', () => loadVideo(), posterElement);
            }
        } else {
            const playVideo = () => {
                removeAttribute('hidden', videoLoading);
                video.play()
            };

            on('playing', function () {
                addClass('is-playing', videoContainer);
                setAttribute('hidden', true, videoLoading);
            }, video);

            on('pause', function () {
                removeClass('is-playing', videoContainer);
            }, video);

            on('ended', function () {
                removeClass('is-playing', videoContainer);
            }, video);

            on('click', () => playVideo(), playButton);
            if (posterElement) {
                on('click', () => playVideo(), posterElement);
            }
        }
    });

}