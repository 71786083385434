import { translate } from '../../../../shared/translations/translations'
import {find, findAll} from "../dom-utils";
import initModulesInScope, {cleanUpInScope} from "../init-modules-in-scope";


export const PLACEMENT_TYPES = {
    CUSTOM: 'PLACEMENT_TYPES_CUSTOM',
    DEFAULT: 'PLACEMENT_TYPES_DEFAULT', // placed in js-alert-notification
    FALLBACK: 'PLACEMENT_TYPES_FALLBACK' // created fallback placement
};

let defaultOptions = {
    key: '__alertNotification',
    errorText: translate('alert-notification.generic-error'),
    closeText: translate('alert-notification.close'),
    renderFunction: defaultRender
};

// set default options
let container = findAll('.js-alert-notification');
let placement;
if (typeof container === 'undefined' || !container) {
    let wrapper = document.createElement("div");
    wrapper.className = "alert-notification alert-notification--fixed ";
    let containerElement = document.createElement("div");
    containerElement.className = "alert-notification__container js-alert-notification";
    wrapper.appendChild(containerElement);
    document.getElementsByTagName("body")[0].appendChild(wrapper);

    container = findAll('.js-alert-notification');

    placement = PLACEMENT_TYPES.FALLBACK
} else {
    placement = PLACEMENT_TYPES.DEFAULT;
}

defaultOptions.container = container;
defaultOptions.placement = placement;

export function init(options) {
    if (options && options.container && options.container.length) {
        options.placement = PLACEMENT_TYPES.CUSTOM;
    }

    defaultOptions = {
        ...defaultOptions,
        ...options,
    };

    if (window[defaultOptions.key]) {
        if (Array.isArray(window[defaultOptions.key])) {
            return window[defaultOptions.key].map(notification => showNotification(notification, options))
        } else {
            return showNotification(window[defaultOptions.key], options);
        }
    }
}

export function showNotification(data, options) {
    let mergedOptions = mergeOptions(defaultOptions, options);

    if (data && data.then && typeof data.then === 'function') {
        // Promise
        return showNotificationByPromise(data, mergedOptions);
    }

    return mergedOptions.renderFunction({
        ...data,
        placement: mergedOptions.placement,
        defaultRender: () => showNotification(data, {
            ...options,
            renderFunction: defaultRender
        })
    }, mergedOptions);
}

export function clearAll(options) {
    options = mergeOptions(defaultOptions, options);

    let containers = options.container;
    containers.map(function(container) {
        container.textContent = '';
        container.setAttribute('hidden', 'hidden');
        cleanUpInScope(container);
    });
}

function defaultRender({type = 'info', title, content, closable = true, styleModifier = '', placement} = {}, options) {
    options = mergeOptions(defaultOptions, options);

    if (content || title) {
        let containers = options.container;

        containers.map(function(container) {
            container.removeAttribute('hidden');
            container.insertAdjacentHTML('beforeend', (
                `<div class="alert alert-${type} alert-dismissible fade show ${styleModifier}" role="alert">
                ${title ? (
                    `<h4 class="alert-heading">
                        ${title}
                    </h4>`
                ) : ''}
                
                ${closable ? (
                    `<button type="button" 
                             class="close" 
                             data-dismiss="alert" 
                             aria-label="${options.closeText}"  
                             title="${options.closeText}">
                            <span aria-hidden="true">&times;</span>
                         </button>`
                ) : ''} 
                ${content ? (
                    `<div>
                        ${content}               
                    </div>`
                ) : ''}
            </div>`));

            initModulesInScope(container);
        });

    }
}

function showNotificationByPromise(promise, options) {
    options = mergeOptions(defaultOptions, options);

    // Unpack json response body if the promise was created via fetch
    promise = promise.then(response => (response
        && response.json
        && typeof response.json === 'function'
        && response.clone
        && typeof response.clone === 'function')
        ? response.clone().json()
        : response
    );

    return promise.then(result => {
        if (result[options.key]) {
            if (Array.isArray(result[defaultOptions.key])) {
                return result[defaultOptions.key].map(notification => showNotification(notification, options))
            } else {
                return showNotification(result[options.key], options);
            }
        }
    }).catch((request, requestState) => {
        if (request.responseJSON) {
            let result = request.responseJSON;
            if (result[defaultOptions.key]) {
                if (Array.isArray(result[defaultOptions.key])) {
                    return result[defaultOptions.key].map(notification => showNotification(notification, options))
                } else {
                    return showNotification(result[options.key], options);
                }
                return;
            }
        }

        if (requestState && requestState === 'abort') {
            // do nothing on jquery abort
            return;
        }

        if(request.name == 'AbortError'){
            // do nothing on fetch abort (https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort)
            return;
        }

        showNotification({
            type: 'danger',
            content: options.errorText,
        }, options);
    });
}

function mergeOptions(defaultOptions, options) {
    let mergedOptions = {...defaultOptions, ...options};

    if (options && options.container && options.container.length && options.container !== defaultOptions.container) {
        mergedOptions.placement = PLACEMENT_TYPES.CUSTOM;
    } else {
        mergedOptions.container = defaultOptions.container;
        mergedOptions.placement = defaultOptions.placement;
    }

    return mergedOptions;
}