"use strict";

import throwError from "../../libs/@elements/throw-error"
import {onFind} from "../../libs/@elements/init-modules-in-scope";
import {findIn} from "../../libs/@elements/dom-utils";
import * as slider from './slider';

const Selectors = {
    Container: '.mai__m3-product-row--has-slider',
    Slider: '.mai__m3-product-row__slider.mai__slider',
}

let containerEl;
let sliderEl;

export function init () {
    onFind(Selectors.Container, (element) => {
        containerEl = element ? element : throwError("M3 Product Row JS – Container missing");
        sliderEl = findIn(Selectors.Slider, containerEl) ? findIn(Selectors.Slider, containerEl) : throwError("M3 Product Row JS – Slider missing")

        sliderEl && slider.init(sliderEl);
    })
}
