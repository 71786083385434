import {cachedFetches} from "../cached-fetches/cached-fetches";

const maxTries = 20;

export function unhideByLoginState(container = document.body) {
    cachedFetches.get('is_user_logged_in').then((isLoggedIn)=> __unhideByLoginState(isLoggedIn, container, 0)).catch(()=>{})
}

/**
 * sometimes the JS loads before the DOM is loaded; IDKW but waiting for window.load doesn't work either
 */
function __unhideByLoginState(isLoggedIn, container = document.body, tryCount) {
    const elements = container.querySelectorAll(':scope [data-show-by-login-state]')
    if (!elements.length && tryCount < maxTries) {
        window.setTimeout(()=> __unhideByLoginState(isLoggedIn, container, tryCount + 1), 100);
    }
    elements.forEach((element) => {
        let unhide = false;
        unhide |= (isLoggedIn && element.dataset.showByLoginState.toLowerCase() === 'logged-in');
        unhide |= (!isLoggedIn && element.dataset.showByLoginState.toLowerCase() === 'not-logged-in');
        if (unhide) {
            element.removeAttribute('hidden');
        }
    })
}
