"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement) {
    const hasDesktopSlider = sliderElement.hasAttribute('data-desktop-slider');

    const splideOptions = {
        perPage: 2,
        perMove: 1,
        padding: {
            left: '1.25rem',
            right: '1.25rem'
        },
        gap: '.625rem',
        arrows: false,
        mediaQuery: 'min',
        pagination: false,
        type: 'slide',
        breakpoints: {
            992: {
                perPage: 4,
                perMove: 1,
                padding: {
                    left: '2.5rem',
                    right: '2.5rem'
                },
                gap: '1.25rem',
                drag: hasDesktopSlider,
                arrows: hasDesktopSlider
            }
        }
    }

    slider = new Splide( sliderElement, splideOptions ).mount();
}
