'use strict'

import {addToAllRequiredCookies, checkAllRequiredCookies, registerOnConsentChangedAction, registerOnNewElementsAction} from './cookieInformation.js'
import {initOverlayButtonsInScope} from './cookieOverlayButtons'
import {readRequiredCookies} from "./helpers";
import {createForEachElementInScopeFunc} from "../initInScope/initInScope";

const selectors = {
    container: '.js-cookie-overlay',
    overlay: '.js-cookie-overlay--overlay',
    hiddenElement: '.js-cookie-overlay--hidden-element',
    hiddenScript: '.js-cookie-overlay--hidden-script',
}

const attributes = {
    requiredCookies: 'data-required-cookies'
}

const states = {
    highlightedCookies: 'js-cookie-overlay__coi-banner-highlighting--'
}

const eachContainerInScope = createForEachElementInScopeFunc(selectors.container)

export function register() {
    registerOnNewElementsAction(initInScope)
    registerOnConsentChangedAction([], tryRemoveOverlays, true)
    registerOnConsentChangedAction([], tryActivateHiddenScripts, true)
}

function initInScope(scope) {
    initOverlayButtonsInScope(scope)
    eachContainerInScope(scope, container => {
        addToAllRequiredCookies(readRequiredCookies(container))
    })
}

const tryRemoveOverlays = (scope) => eachContainerInScope(scope, container => {
    if (!checkAllRequiredCookies(readRequiredCookies(container))) {
        return
    }

    container.querySelectorAll(':scope ' + selectors.overlay).forEach(overlay => {
        overlay.remove()
    });

    container.querySelectorAll(':scope ' + selectors.hiddenElement).forEach(hiddenElement => {
        hiddenElement.outerHTML = hiddenElement.dataset.html
    });
})

const tryActivateHiddenScripts = (scope) => {
    //HTMLScriptElements can't be just rendered (innerHTML), they have to be added by DOM
    function setInnerHTML(element, html) {
        element.innerHTML = html;

        const oldScriptEl = element.querySelector("script")
        const newScriptEl = document.createElement("script");

        Array.from(oldScriptEl.attributes).forEach(attr => {
            newScriptEl.setAttribute(attr.name, attr.value)
        });

        const scriptText = document.createTextNode(oldScriptEl.innerHTML);
        newScriptEl.appendChild(scriptText);
        element.after(newScriptEl)
        element.remove()
    }

    eachContainerInScope(scope, container => {
        if (!checkAllRequiredCookies(readRequiredCookies(container))) {
            return
        }

        container.querySelectorAll(':scope ' + selectors.hiddenScript).forEach(element => {
            setInnerHTML(element, element.dataset.html)
        })
    })
}

export function addCookieOverlayHighlightClasses(cookieOverlayButtonElem) {
    if (!cookieOverlayButtonElem) return

    let container = cookieOverlayButtonElem.closest(selectors.container)

    if (!container) return

    const requiredCookies = container.getAttribute(attributes.requiredCookies) ? JSON.parse(container.getAttribute(attributes.requiredCookies)) : false

    if (!requiredCookies || !requiredCookies.length) return

    requiredCookies.forEach(requiredCookie => {
        document.body.classList.add(states.highlightedCookies + requiredCookie);
    });
}

export function removeCookieOverlayHighlightClasses() {
    let highlightClassesToBeRemoved = [];

    Array.from(document.body.classList).forEach(classListItem => {
        classListItem.indexOf(states.highlightedCookies) > -1 && highlightClassesToBeRemoved.push(classListItem)
    });

    highlightClassesToBeRemoved.forEach(highlightClassToBeRemoved => {
        document.body.classList.remove(highlightClassToBeRemoved);
    });

}
