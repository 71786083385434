'use strict'

let overrides = {};
export function override(obj) {
    overrides = {
        ...overrides,
        ...obj
    }
}

export function getLoadingContainer(overlay = true, sticky = true){
    if (overrides.getLoadingContainer) {
        return overrides.getLoadingContainer(overlay, sticky);
    }

    return(
        '<div class="load-container ' + (sticky ? 'load-container--sticky' : '') + '">' +
            '<div class="loader">' +
                '<div></div>' +
                '<div></div>' +
                '<div></div>' +
                '<div></div>' +
                '<div></div>' +
                '<div></div>' +
                '<div></div>' +
                '<div></div>' +
                '<div></div>' +
            '</div>' +
        '</div>' +
        (overlay ? '<div class="load-container-bg"></div>' : '')
    )
}

export function removeLoadingContainer($scope) {
    $scope.find('.load-container, .load-container-bg, .load-container-small').remove();
}
export function addLoadingContainer($scope, config = [], big = true) {
    if(big){
        $scope.prepend(getLoadingContainer(...config));
    }else{
        if($scope.css('position') !== 'absolute' || 'relative'){
            $scope.css({
                'position' : 'relative'
            });
        }
        $scope.prepend(getLoadingContainerSmall(...config));
    }
}

export function getLoadingContainerSmall() {
    return(
        '<div class="load-container-small">' +
            '<div class="loader-small">' +
                '<div></div>' +
                '<div></div>' +
                '<div></div>' +
                '<div></div>' +
            '</div>' +
        '</div>'
    )
}

export function getSuccessContainer() {
    return(
        '<div class="loader-success-container bg-primary">' +
            '<i class="icon icon-check text-white"></i>' +
        '</div>'
    )
}

export function addSuccessContainer($scope) {
    if($scope.css('position') !== 'absolute' || 'relative'){
        $scope.css({
            'position' : 'relative'
        });
    }
    $scope.prepend(getSuccessContainer());
    setTimeout(function () {
        $scope.find('.loader-success-container').remove();
    }, 4000)
}
