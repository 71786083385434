import {getConfigValue, requireConfigValues, getConfig} from '../libs/@elements/config-utils';
import initModulesInScope from '../libs/@elements/init-modules-in-scope';
import {showNotification, clearAll} from '../libs/@elements/alert-notification';
import Modal from 'bootstrap/js/dist/modal';
import 'url-search-params-polyfill'; // Edge Polyfill
import 'whatwg-fetch'; // IE10 Polyfill
import fetch from '../libs/@elements/fetch';
import formDataEntries from 'form-data-entries';
import {find, findIn, findAll, findAllIn, on, removeAttribute, setAttribute, addClass, removeClass, closest} from '../libs/@elements/dom-utils';
import {onFind} from "../libs/@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "../libs/@elements/data-set-utils";
import {handleTrackingOnPromise} from "./tracking";
import {closeAll} from "./header";
const configName = '_cartConfig';
import ADD_TO_CART_FORM_DISABLED_STATE from './m26-checkout-cta-size-select/size-change'

let $listLoading,
    $listResult,
    $cartResult,
    $cartNotifications,
    $count;

const defaultSelectors = {
    base: '.js-cart',
    form: '.js-cart__form',
    overviewForm: '.js-cart__form--overview',
    addButton: '.js-cart__add',
    removeButton: '.js-cart__remove',
    loading: '.js-cart__loading',
    result: '.js-cart__result',
    notifications: '.js-cart__notifications',
    count: '.js-cart-count',
    autoSubmitInputs: '.js-cart__autosubmit-change',
    closeButton: '.js-header__dropdown-close',
};

const defaultOptions = {
    formTrigger: 'submit',
    emptyCart: false,
    onAdd: () => {},
    onAddSucceed: () => {},
    onAddFailed: () => {},
    formTriggerBlockedState: ADD_TO_CART_FORM_DISABLED_STATE
};


export function init(options = defaultOptions, selectors = defaultSelectors){
    $listLoading = find('.js-cart__list-loading');
    $listResult = find('.js-cart__list-result');
    $count = findAll(selectors.count);
    $cartNotifications = find('.js-cart__notification');


    // initial cart list
    // initial cart count
    if ($listResult) {
        //window.cachedFetches.get('cart_count').then((cartCount) => setCount($count, cartCount));  //todo: uncomment if shop is activated
        getCartDropdown();
    }


    onFind(selectors.form, function (baseElement) {
        createCartForm(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });

    // remove product from cart
    onFind(selectors.removeButton, function (button) {
        let baseElement = closest(selectors.form, button);

        on('click', function (evt) {
            evt.preventDefault();

            let params;

            if (button.getAttribute('data-product-id')) {
                params = new URLSearchParams({
                    removeId: button.getAttribute('data-product-id')
                });
            } else if (button.getAttribute('data-token-id')) {
                params = new URLSearchParams({
                    removeToken: button.getAttribute('data-token-id')
                });
            }

            if (baseElement.getAttribute('data-cart-dropdown')) {
                updateDropdownCart(baseElement.getAttribute('data-action'), params, options, baseElement, $listLoading);
            } else {
                updateCart(baseElement.getAttribute('data-action'), params, options, baseElement);
            }
        }, button)
    });

    onFind(selectors.autoSubmitInputs, function (submitElement) {
        let baseElement = closest(selectors.form, submitElement);
        on('change', function () {
            let formDataEntries = getFormDataEntries([baseElement]);
            let params = new URLSearchParams(formDataEntries);

            if (options.dropdown) {
                let url = baseElement.getAttribute('data-action') || getConfigValue('cartInfoUrl', configName);
                updateDropdownCart(url, params, options, baseElement, $listLoading);
            } else {
                let url = baseElement.getAttribute('data-action') || getConfig('_addToCart').url;
                updateCart(url, params, options, baseElement);
            }
        }, submitElement);
    });

    onFind(selectors.addButton, function (buttonElement) {
        removeAttribute('disabled', buttonElement);
    });

    onFind(selectors.form + ' ' + selectors.closeButton, function(closeElement) {
        closeElement.addEventListener('click', ()=>{
            closeAll()
        })
    });
}

export function createCartForm(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    $cartResult = find(selectors.result);

    options = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('cart', baseElement)
    };

    if (baseElement && baseElement.length) {
        let formTrigger = options.formTrigger;

        if (options.dropdown) {
            requireConfigValues(['cartInfoUrl'], configName);
        } else {
            requireConfigValues(['url'], '_addToCart');
        }

        on(formTrigger, function (evt) {
            evt.preventDefault();

            if (baseElement.classList.contains(options.formTriggerBlockedState)) {
                return false;
            }

            clearAll({
                $container: $cartNotifications
            });

            let formDataEntries = getFormDataEntries([baseElement]);
            let params = new URLSearchParams(formDataEntries);

            if (options.dropdown) {
                let url = baseElement.getAttribute('data-action') || getConfigValue('cartInfoUrl', configName);
                updateDropdownCart(url, params, options, baseElement, $listLoading);
            } else {
                let url = baseElement.getAttribute('data-action') || getConfig('_addToCart').url;
                updateCart(url, params, options, baseElement);
            }

            call(options.onAdd);
        }, baseElement);
    }

}

function updateCart(url, params, options, baseElement) {
    let loadingElement = findIn('.js-cart__loading', baseElement);
    removeAttribute('hidden', loadingElement);

    addClass('is-loading', baseElement);

    let pendingRequest = fetch(url, {
        body: params
    });

    pendingRequest.then((result) => {
        return result.clone().json()
    }).then((result) => {
        if (result.success) {
            if (result.html && $cartResult) {
                $cartResult.innerHTML = result.html;
                initModulesInScope($cartResult);
            }

            //update cart dropdown
            getCartDropdown();

            if (typeof result.count !== "undefined") {
                setCount($count, result.count);
            }

            addClass('is-finished', baseElement);

            let t = setTimeout(() => {
                removeClass('is-finished', baseElement);

                clearTimeout(t);
            }, 3000);


            call(options.onAddSucceed);

            if(result.modal){
                let modalResult = result.modal
                let ajaxModal = document.getElementById('js-availability-ajax-modal');
                let ajaxModalBody = document.getElementById('js-availability-ajax-modal__body');

                ajaxModalBody.innerHTML = modalResult;

                let ajaxModalInstance = Modal.getInstance(ajaxModal);
                ajaxModalInstance.show();
            }
        }else{
            call(options.onAddFailed);
        }

        setAttribute('hidden', true, loadingElement);
        removeClass('is-loading', baseElement);

    }).catch((error) => {
        setAttribute('hidden', true, loadingElement);
        removeClass('is-loading', baseElement);

        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    });

    showNotification(pendingRequest, {
        $container: $cartNotifications
    });

    handleTrackingOnPromise(pendingRequest);
}

function getCartOverview() {
    let loadingElement = findIn('.js-cart__loading', closest('.js-cart__form', $cartResult));
    requireConfigValues(['cartUpdateUrl'], configName);

    let request = fetch(getConfigValue('cartUpdateUrl', configName), {
        method: 'get',
    });

    removeAttribute('hidden', loadingElement);

    showNotification(request);

    request.then(response => response.json())
        .then(result => {
            if (result.success) {
                $cartResult.innerHTML = result.html;
                initModulesInScope($cartResult);
            }
            setAttribute('hidden', true, loadingElement);

        }).catch(e => {
        console.warn(e);
        setAttribute('hidden', true, loadingElement);
    });
}

function updateDropdownCart(url, params, options, baseElement, loadingSpinner = null) {
    if (loadingSpinner) {
        removeAttribute('hidden', loadingSpinner);
    }

    addClass('is-loading', baseElement);

    let pendingRequest = fetch(url, {
        body: params
    });

    pendingRequest.then((result) => {
        return result.clone().json()
    }).then((result) => {
        if (result.success) {
            if (result.success) {
                setListContent($listResult, $listLoading, result.html);

                if (typeof result.count !== "undefined") {
                    setCount($count, result.count);
                }

                if ($cartResult) {
                    getCartOverview();
                }
            }

            call(options.onAddSucceed);
        }else{
            call(options.onAddFailed);
        }

        if (loadingSpinner) {
            setAttribute('hidden', true, loadingSpinner);
        }
        removeClass('is-loading', baseElement);

    }).catch((error) => {
        if (loadingSpinner) {
            setAttribute('hidden', true, loadingSpinner);
        }

        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    });

    handleTrackingOnPromise(pendingRequest);
}

export function getCartDropdown() {
    requireConfigValues(['cartInfoUrl'], configName);

    let request = fetch(getConfigValue('cartInfoUrl', configName), {
        method: 'get',
    });

    removeAttribute('hidden', $listLoading);

    showNotification(request);

    request.then(response => response.json())
        .then(result => {
            if (result.success) {
                setListContent($listResult, $listLoading, result.html);

                if (typeof result.count !== "undefined") {
                    setCount($count, result.count);
                }
            }
            if(result.modal){
                let modalResult = result.modal
                let ajaxModal = document.getElementById('js-availability-ajax-modal');
                let ajaxModalBody = document.getElementById('js-availability-ajax-modal__body');

                ajaxModalBody.innerHTML = modalResult;

                let ajaxModalInstance = Modal.getInstance(ajaxModal);
                ajaxModalInstance.show();
            }
        }).catch(e => {
        console.warn(e);
        setAttribute('hidden', true, $listLoading);
    });
}

function getFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        for(let pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }
    });

    return formDataArray;
}

function setCount(elements, count) {
    if (count) {
        elements.map((item) => {
            removeAttribute('hidden', item);
            item.textContent = count;
        });
    } else {
        elements.map((item) => {
            setAttribute('hidden', true, item);
        });
    }
}

function setListContent($listResult, $listLoading, content) {
    if (content) {
        $listResult.innerHTML = content;
        setAttribute('hidden', true, $listLoading);
        removeAttribute('hidden', $listResult);
        initModulesInScope($listResult);
    } else {
        setAttribute('hidden', true, $listLoading);
        setAttribute('hidden', true, $listResult);
    }
}

// Call the given function if it really is one
function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}
