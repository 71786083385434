import {onFind} from "../libs/@elements/init-modules-in-scope";
import {findIn, findAll} from "../libs/@elements/dom-utils";

const Selectors = {
    customFilesContainer: '.js-custom-file',
    customFileName: '.js-custom-file__name'
}

export function init() {

    onFind(Selectors.customFilesContainer, (element) => {

        const customFiles = findAll(Selectors.customFilesContainer);
        if(customFiles.length > 0) {
            
            customFiles.forEach((customFile) => {
                let input = findIn('input', customFile);
                let filenameContainer = findIn(Selectors.customFileName, customFile);

                input.addEventListener('change', (event) => {
                    const filename = event.target.value.split('\\').pop();
                    if(filename) {
                        filenameContainer.textContent = filename;
                        input.classList.add('has-value');
                    } else {
                        input.classList.remove('has-value');
                        filenameContainer.textContent = '';
                    }
                    
                }, false);
            });
        }
    });
}