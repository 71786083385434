import '../../../libs/@elements/menu-aim';

import Collapse from 'bootstrap/js/dist/collapse';
import outerHeight from '../../../libs/helpers/outerHeight'
import MenuAim from "../../../libs/@elements/menu-aim";
import {closest} from "../../../libs/@elements/dom-utils";

export function init() {

    const scope = document.getElementById('mainContent');

    const dropDown = scope.querySelector(':scope .js-in-page-menu__dropdown')
    if(!dropDown) {
        return;
    }
    const menu = dropDown.querySelector(':scope .js-in-page-menu__menu-aim')
    if(!menu) {
        return;
    }

    new DropdownMenu(dropDown, menu).init();
}


class DropdownMenu {
    constructor(dropDown, menu) {
        this.menuAim = null;
        this.menu = menu;
        this.dropdown = dropDown;
        this.closeSecondLevels = menu.querySelectorAll(':scope .js-close-second-level')
        this.firstLevels = menu.querySelectorAll(':scope .js-in-page-menu__first-level')
        this.collapseInstance = null;
    }

    init() {
        this.initApplicationCollapse()
        this.initTouchdevice()
        this.initDropdown()

        this.onBodyClickHandler = e => this.onBodyClickHandler_inner(e)
        this.onCloseListener = e => this.onCloseListenerInner(e)
        this.onFirstLevelClick = e => this.onFirstLevelClickInner(e)
    }

    initApplicationCollapse() {
        const deviceWithHover = window.matchMedia('screen and (hover: hover) and (pointer: fine)').matches;
        console.log(deviceWithHover ? 'device WITH hover': 'no hover')
        if (deviceWithHover) {
            this.initDeviceWithHover();
        } else {
            this.initDeviceWithoutHover();
        }

        this.collapseInstance = Collapse.getOrCreateInstance(this.dropdown);
    }

    onBodyClickHandler_inner(e) {
        if (this.dropdown.classList.contains('collapsing')) {
            return;
        }

        if (e.target.classList.contains('.js-in-page-menu__dropdown')) {
            return;
        }

        if (closest('.js-in-page-menu__dropdown', e.target)) {
            return;
        }
        e.preventDefault();
        this.collapseInstance.hide();
    }

    initDropdown() {
        this.dropdown.addEventListener('show.bs.collapse', (e) => {
            this.deactivateSubmenu()
            document.body.addEventListener('click', this.onBodyClickHandler);
        })

        this.dropdown.addEventListener('click.hide.bs.collapse', (e) => {
            this.deactivateSubmenu()
            document.body.removeEventListener('click', this.onBodyClickHandler);
        });
    }

    activateSubmenu(row, e = null) {
        let menuHeight = outerHeight(this.menu);

        const secondLevelElements = row.querySelectorAll(':scope .js-in-page-menu__second-level');
        if (secondLevelElements.length === 0) {
            return;
        }

        secondLevelElements.forEach((element) => {
            element.removeAttribute('hidden');
        });

        if (outerHeight(secondLevelElements[0]) > menuHeight) {
            this.menu.style.minHeight = (outerHeight(secondLevelElements[0]) + 2) + "px";
        }

        this.closeSecondLevels.forEach((el) => {
            el.addEventListener('click', this.onCloseListener);
        })

        if (e !== null) {
            e.preventDefault();
        }
    }

    deactivateSubmenu(row) {
        let hideTarget;
        if (row) {
            hideTarget = row;
            this.closeSecondLevels.forEach((el) => {
                el.removeEventListener('click', this.onCloseListener)
            })
        } else {
            hideTarget = this.menu;
        }

        hideTarget.querySelectorAll(':scope .js-in-page-menu__second-level').forEach(e => {
            e.setAttribute('hidden', 'hidden');
        })

        this.menu.style.minHeight = '';
    }

    onCloseListenerInner(e) {
        this.deactivateSubmenu(closest('.js-in-page-menu__first-level', e.target));

        e.stopPropagation();
        e.preventDefault();
    }


    onFirstLevelClickInner(e) {
        if (closest('.js-in-page-menu__second-level-list', e.target)) {
            return;
        }
        if (!e.currentTarget.querySelector(':scope .js-in-page-menu__first-level-child')) {
            return;
        }
        this.activateSubmenu(e.currentTarget, e);
    }

    initDeviceWithoutHover() {
        this.firstLevels.forEach((el) => {
            el.addEventListener('click', this.onFirstLevelClick)
        })
    }

    initDeviceWithHover() {
        this.menuAim = new MenuAim(this.menu, {
            activate: (row) => this.activateSubmenu(row),
            deactivate: (row) => this.deactivateSubmenu(row),
            exitMenu: () => false,
        });
        this.menuAim.init();
    }

    initTouchdevice() {
        let openMenu;
        const t = this.menu.querySelectorAll(':scope .js-in-page-menu__first-level-child-link');

        t.forEach((firstLevelChildLink) => {
            firstLevelChildLink.addEventListener('touchend', (e) => {
                if (openMenu) {
                    this.deactivateSubmenu(openMenu);
                }

                const closest1 = closest('.js-in-page-menu__first-level', e.currentTarget)
                if (!closest1) {
                    return;
                }

                if (closest1.querySelectorAll(':scope .js-in-page-menu__second-level').length === 0) {
                    return;
                }
                const closestFromMenu = closest('.js-in-page-menu__first-level', e.currentTarget)

                openMenu = closestFromMenu;
                e.preventDefault();
                this.activateSubmenu(closestFromMenu, e);
            });
        });
    }

}