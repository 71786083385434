"use strict";

import throwError from "../../../libs/@elements/throw-error"
import {onFind} from "../../../libs/@elements/init-modules-in-scope";
import {findIn} from "../../../libs/@elements/dom-utils";
import * as slider from './slider';

const Selectors = {
    Container: '.mai__product-card--show-colour-variants',
    Slider: '.mai__product-card__slider.mai__slider',
    NavSlider: '.mai__product-card__nav-slider.mai__slider',
}

let containerEl;
let sliderEl;
let navSliderEl;

export function init () {
    onFind(Selectors.Container, (element) => {
        containerEl = element ? element : throwError("Product card JS – Container missing");
        sliderEl = findIn(Selectors.Slider, containerEl) ? findIn(Selectors.Slider, containerEl) : throwError("Product card JS – Slider missing")
        navSliderEl = findIn(Selectors.NavSlider, containerEl) ? findIn(Selectors.NavSlider, containerEl) : false;

        sliderEl && slider.init(sliderEl, navSliderEl);
    })
}
