/** @jsx h */

import {h, render} from "preact";
import {components}  from 'react-select'
import AsyncSelect  from 'react-select/async'
import {onFind} from "../libs/@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "../libs/@elements/data-set-utils";
import throwError from "../libs/@elements/throw-error";
import {useState} from "preact/hooks";
import { translate } from '../../shared/translations/translations'

const defaultSelectors = {
    base: '.js-typeahead'
};

const defaultOptions = {
    isClearable: true,
    defaultValue: "",
    minLength: 2
};

const defaultAttributes = {
    xNumberOfItemsFoundMessage: 'data-typeahead-x-number-of-items-found-message'
}

let xNumberOfItemsFoundMessage = '';
let currentSearchString = '';
let lastSearchString = '';
let externalAction = null;

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        let options = {
            ...defaultOptions,
            ...options,
            ...transformDataSetOptions(getPrefixedDataSet('typeahead', baseElement))
        };

        baseElement = baseElement;
        xNumberOfItemsFoundMessage = baseElement.getAttribute(defaultAttributes.xNumberOfItemsFoundMessage);

        render(<TypeaheadConfig isClearable={options.isClearable}
        placeholder={options.placeholder}
        value={options.defaultValue}
        defaultIsOpen={false}
        name={options.name}
        minLength={options.minLength}
        groups={options.groups}
        action={options.action}
        defaultOptions={options.options}/>,
        baseElement);
    });
}

function TypeaheadConfig({
                             defaultOptions = [],
                             value = "",
                             placeholder = "",
                             defaultIsOpen = false,
                             name = "q",
                             minLength = 0,
                             groups = [],
                             action = null,
                         }) {


    const [inputValue, setInputValue] = useState(value);
    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    const loadData = (stringValue, callback) => {
        const _config = window._config || {};
        if (!_config.typeaheadDataUrl) {
            console.warn(`"_config.typeaheadDataUrl" is not set`);
        }

        let data = {
            q: stringValue
        };

        externalAction = action;

        const url = addParamsToUrl(_config.typeaheadDataUrl, data);
        groups = JSON.parse(groups);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(result => result.json()).then(result => {
            const options = [];
            options.push({label: '', isPlaceholder: true});
            for (let i = 0; i < groups.length; i++) {
                let newGroup = {
                    label: groups[i].title,
                    overviewUrl: groups[i].overviewUrl,
                    isProduct: !!groups[i].isProduct,
                    options: result[groups[i].name],
                    overviewText: groups[i].overviewText,
                };

                options.push(newGroup);
            }

            callback(options);
        }).catch(e => {
            console.error(`Could not load configuration data for typeahead`, e);
        });
    };


    const handleInputChange = (newValue) => {
        let input = newValue;
        setInputValue({ input });
        if(input.length > +minLength) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
        if (newValue.length === 0) lastSearchString = currentSearchString;
        currentSearchString = input;
        return input;
    };

    const handleChange = (currentSelection) => {
        if (currentSelection.isPlaceholder) {
            handleSubmit();
        } else {
            window.location = currentSelection.url;
        }
    };

    const handleSubmit = (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        window.location.href = action + '?q=' + encodeURIComponent(inputValue.input);
    };

    const styling = {
        control: styles => ({ ...styles, backgroundColor: 'transparent', borderWidth: '0' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#fff' : 'transparent',
            }
        },
        menu: styles => ({ ...styles, backgroundColor: '#fff'})
    };

    return (
        <form action={action} onSubmit={(evt) => handleSubmit(evt)}>
            <AsyncSelect
                name={name}
                className="react-select-wrapper"
                classNamePrefix="react-select"
                cacheOptions
                menuIsOpen={isOpen}
                loadOptions={isOpen ? loadData : ''}
                defaultOptions
                loadingMessage={() => translate('search-loading')}
                placeholder={placeholder}
                styles={styling}
                onInputChange={handleInputChange}
                onChange={handleChange}
                components={{ ValueContainer, MenuList, Group, GroupHeading, Option }}
            />
        </form>
)
}

const ValueContainer = ({ children, ...props }) => {
    const handleBtnClick = (evt) => {
        evt.stopPropagation();

        if (!lastSearchString.length) {
            return;
        }

        window.location.href = externalAction + '?q=' + encodeURIComponent(lastSearchString);
    };

    return (
        <components.ValueContainer {...props}>
            {children}
            <button type="button" className="mai__btn mai__btn--red typeahead__value-container-submit" onClick={(evt) => handleBtnClick(evt)}><i className="icon icon-search m-0"></i></button>
        </components.ValueContainer>
    );
};

const MenuList = ({ children, ...props }) => {
    let itemCount = 0;

    if (props.options.length) {
        itemCount = getItemCount(props.options)
    }

    const menuListHeadlineString = xNumberOfItemsFoundMessage.replace('%d', itemCount.toString());

    return (
        <components.MenuList {...props}>
            { !props.isLoading ? (
                <div className="typeahead__head">
                    <h2 className="text-black-50 typeahead__headline">{menuListHeadlineString}<span class="text-black typeahead__headline-highlight">{currentSearchString}</span></h2>
                </div>
            ) : null}
            {children}
        </components.MenuList>
    );
};

const Group = props => {
    let isProduct = !!props.data.isProduct;

    return (
        <components.Group {...props}>
            <div className={isProduct ? 'gx-2 gy-2 row typeahead__group--product' : 'gy-2 row typeahead__group'}>
                {props.children}
            </div>
        </components.Group>
    );
};


const GroupHeading = props => {
    let showLink = !!props.data.overviewUrl;
    let overviewUrl = props.data.overviewUrl + '?q=' + encodeURIComponent(props.selectProps.inputValue);

    if (showLink && props.data.overviewUrl.indexOf('?') > -1) {
        overviewUrl = props.data.overviewUrl + '&q=' + encodeURIComponent(props.selectProps.inputValue);
    }

    return (
        <components.GroupHeading {...props}>
            <div className="row groupHeading">
                <div className="col">{props.children}</div>
                {showLink ? (
                    <div className="col-7 text-end">
                        <a className="react-select__group-link" href={overviewUrl}>
                            {props.data.overviewText}
                        </a>
                    </div>
                ) : null}
            </div>
        </components.GroupHeading>
    );
};

const Option = props => {
    let hasImage = !!props.data.imgurl;
    let isPlaceholder = props.data.isPlaceholder;
    let isProduct = props.data.price;
    let isMagazine = !isProduct && hasImage;

    if (isProduct) {
        props.className = 'col-12 col-md-3 py-0';
    } else {
        props.className = 'col-12';
    }

    // TODO: Replace hardcoded props.data.labels.length if queries with forEach loop, can't figure out correct syntax

    return (
        <components.Option {...props}>
            {!isPlaceholder && isProduct ? (
                <div className="mai__product-card row">
                    <div className="mai__product-card__head col-4 col-md-12 mb-md-4">
                        <a href={props.data.url} className="mai__product-card__image-container d-block">
                            <picture className="mai__product-card__image-wrapper px-6 py-7 d-block">
                                <img className="img-fluid mai__product-card__image" src={props.data.imgurl} alt={props.data.title} title={props.data.title} />
                            </picture>
                            <div className="mai__product-card__image-background"></div>
                        </a>
                    </div>
                    <div className="mai__product-card__body col">
                        <div className="mai__product-card__body-inner row align-items-start d-block d-md-flex">
                            <a href={props.data.url} className="mai__product-card__content col-12 col-lg d-block pb-4 pb-md-0">
                                <h2 className="mai__product-card__headline mb-0">
                                    {props.data.categoryName ? (
                                        <strong>{props.data.categoryName}<br /><span className="text-uppercase">{props.data.title}</span></strong>
                                    ) : (
                                        <strong className="text-uppercase">{props.data.title}</strong>
                                    )}
                                </h2>
                                <p className="mai__product-card__price product-teaser__price mt-1 mb-0">{props.data.price}</p>
                                {props.data.labels.length ? (
                                    <div class="mai__product-card__badge-container d-flex flex-wrap mt-2 mb-n2 mb-xxxl-n3 position-static">
                                        {props.data.labels[0] ? (
                                            <div class="mai__product-card__badge-item p-1 p-xxxl-2 me-2 me-lg-3 me-xxxl-5 mb-2 mb-xxxl-3">
                                                {props.data.labels[0].name}
                                            </div>
                                        ) : null}
                                        {props.data.labels.length == 2 ? (
                                            <div class="mai__product-card__badge-item p-1 p-xxxl-2 me-2 me-lg-3 me-xxxl-5 mb-2 mb-xxxl-3">
                                                {props.data.labels[1].name}
                                            </div>
                                        ) : null}
                                        {props.data.labels.length == 3 ? (
                                            <div class="mai__product-card__badge-item p-1 p-xxxl-2 me-2 me-lg-3 me-xxxl-5 mb-2 mb-xxxl-3">
                                                {props.data.labels[2].name}
                                            </div>
                                        ) : null}
                                        {props.data.labels.length == 4 ? (
                                            <div class="mai__product-card__badge-item p-1 p-xxxl-2 me-2 me-lg-3 me-xxxl-5 mb-2 mb-xxxl-3">
                                                {props.data.labels[3].name}
                                            </div>
                                        ) : null}
                                    </div>
                                ) : null}
                            </a>
                        </div>
                    </div>
                </div>
            ) : null }
            {!isPlaceholder && !isProduct ? (
                <div className="stretch-link option">
                    <div className="row align-items-center">
                        <div className="col">
                            <div>
                                <a href={props.data.url} className={isMagazine ? ('typeahead__title typeahead__magazine-link stretch-link__link text-black-50') : ('typeahead__title stretch-link__link h2')}>
                                    {props.data.title}
                                </a>
                            </div>
                            {props.data.text ? (
                                <div className="mt-2">{props.data.text}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null }
        </components.Option>
    );
};

function getItemCount(optionsArr = []) {
    let itemCount = 0;

    optionsArr.forEach(optionItem => {
        if (optionItem.options && optionItem.options.length) {
            itemCount += optionItem.options.length;
        }
    });

    return itemCount;
}

function transformDataSetOptions(options = {}) {
    let transformedOptions = {...options};

    if (transformedOptions.options) {
        try {
            transformedOptions.options = JSON.parse(transformedOptions.options)
        } catch (e) {
            transformedOptions.options = null;
            throwError(OPTIONS_ERROR_MESSAGE);
        }
    }

    return transformedOptions;
}

export const addParamsToUrl = (url, params) =>
    url + (url.indexOf('?') >= 0 ? '&': '?')
    + Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');


const OPTIONS_ERROR_MESSAGE = `Typeahead error: data-typeahead-options has to be a a valid JSON object. Most likely you used single quotes instead of double quotes for the JSON fields.`;
