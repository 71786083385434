"use strict";

export function pauseVideo(video) {
    if (video instanceof HTMLIFrameElement) {
        sendYTVideoCommand(video, 'pauseVideo')
    } else {
        video.pause();
    }
}

export function playVideo(video) {
    if (video instanceof HTMLIFrameElement) {
        sendYTVideoCommand(video, 'playVideo')
    } else {
        video.play();
    }
}

export function seekTo(video, second) {
    if (video instanceof HTMLIFrameElement) {
        sendYTVideoCommand(video, 'seekTo', [second, true]);
    } else if (video instanceof HTMLVideoElement) {
        video.currentTime = second
    } else {
        //probably video-js
        video.currentTime(second);
    }
}

function sendYTVideoCommand(iframe, func, args = []) {
    iframe.contentWindow.postMessage(JSON.stringify({
        "event": "command",
        "func": func,
        "args": args
    }), "*");
}

