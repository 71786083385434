"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement) {
    const splideOptions = {
        autoWidth: true,
        padding: {
            left: '1.25rem',
            right: '1.25rem'
        },
        focus: 'center',
        gap: '2.5rem',
        arrows: false,
        mediaQuery: 'min',
        classes: {
            pagination: 'splide__pagination mai__slider-pagination'
        },
        type: 'slide',
        speed: 750,
        rewind: true,
        rewindSpeed: 2000,
        easing: 'cubic-bezier(0.075, 0.885, 0.22, 1.275)',
        breakpoints: {
            992: {
                padding: {
                    left: '2.5rem',
                    right: '2.5rem'
                },
                gap: '8.4375rem',
                arrows: true,
            }
        }
    }

    slider = new Splide( sliderElement, splideOptions ).mount();
}
