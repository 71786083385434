'use strict'

import {setAllAsNotInitialized} from "../initializationState/initializationState";

const selectors = {
    lgContainer: '.lg-inner'
}

/** initialize overlays who have been copied to the lightgallery overlay */
export function initializeCookieOverlaysInLightGallery() {
    const lgContainer = document.querySelector(selectors.lgContainer)
    if (!lgContainer) {
        return
    }

    setAllAsNotInitialized(lgContainer)
    window.initializeCookieElements(lgContainer)
}
