"use strict";

import throwError from "../../libs/@elements/throw-error"
import {onFind} from "../../libs/@elements/init-modules-in-scope";
import {findIn, findAllIn} from "../../libs/@elements/dom-utils";
import * as slider from './slider';

const Selectors = {
    Container: '.mai__m2-category-slider',
    Slider: '.mai__m2-category-slider__content',
    SlideItem: '.mai__m2-category-slider__item',
    SplideNodeType: '[data-splide-node]'
}

const Classnames = {
    Splide: {
        splide: 'splide',
        track: 'splide__track',
        list: 'splide__list',
        slide: 'splide__slide',
        slidecontainer: 'splide__slide__container'
    }
}

const Attributes = {
    SplideNodes: 'data-splide-node'
}

let containerEl;
let sliderEl;
const mediaDesktop = "(min-width: 992px)";
const mobileMinimumSlides = 2;
const desktopMinimumSlides = 5;

export function init () {
    onFind(Selectors.Container, (element) => {
        containerEl = element ? element : throwError("M2 Category Slider JS – Container missing");
        sliderEl = findIn(Selectors.Slider, containerEl) ? findIn(Selectors.Slider, containerEl) : throwError("M2 Content Slider JS – Slider missing")

        const slides = findAllIn(Selectors.SlideItem, sliderEl);
        const isMobileView =  !window.matchMedia(mediaDesktop).matches;

        if((isMobileView && slides.length >= mobileMinimumSlides) || (!isMobileView && slides.length >= desktopMinimumSlides)) {
            addSplideClasses();
            sliderEl && slider.init(sliderEl, isMobileView);
        }

    })
}

function addSplideClasses() {
    let splideNodes = findAllIn(Selectors.SplideNodeType, containerEl);
    if(splideNodes.length > 0) {
        splideNodes.forEach((element) => {
            let splideNodeType = element.getAttribute(Attributes.SplideNodes);
            if(splideNodeType) {
                element.classList.add(Classnames.Splide[splideNodeType]);
            }
        })
    }
}
