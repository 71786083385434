"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement, isMobileView = false) {
    const splideOptions = {
        type: 'slide',
        autoWidth: true,
        cover: true,
        arrows: !isMobileView,
        mediaQuery: 'min',
        perMove: 1,
        perPage: 1,
        padding: { left: '1.25rem', right: '1.25rem' },
        gap: '1.25rem',
        breakpoints: {
            992: {
                padding: { left: '2.5rem', right: '2.5rem' },
                perPage: 3,
            }
        },
        pagination: false,
        classes: {
            arrows: 'mai__m1-magazine-teaser__arrows mai__slider-arrows splide__arrows',
            arrow : 'splide__arrow mai__slider-arrow',
        }
    }

    slider = new Splide( sliderElement, splideOptions ).mount();
}
