"use strict";

import throwError from "../../libs/@elements/throw-error"
import {onFind} from "../../libs/@elements/init-modules-in-scope";
import {findAllIn, findAll, triggerWith, removeClass, addClass, findIn} from "../../libs/@elements/dom-utils";

export const Selectors = {
    Container: '.mai__m26-checkout-cta-size-select__modal',
    SizeRadios: '.mai__m26-checkout-cta-size-select__size.js-selected-size--radio input',
    SizePrice: '.mai__m26-checkout-cta-size-select__size-price',
    SizePriceRaw: '.mai__m26-checkout-cta-size-select__size-price-raw',
    SizeText: '.mai__m26-checkout-cta-size-select__size-text',
    VendorContainer: '.mai__m26-checkout-cta-size-select__outtra-vendor-wrapper',
    ColorVariants: '.mai__m26-checkout-cta-size-select__color-variant',
    AddToCartForm: '.js-cart__form',
    AddToCartButton: '.js-cart__form button[data-cart-product-id]',
    AddToCartButtonArticleIdInput: '.js-cart__form input[name=articleId]',
    AddToCartInfo: '.js-cart__info',
    DeliveryDateInfo: '.js-m26-checkout-cta-size-select__delivery-date-info',
    PoweredByStorer: '.js-m26-checkout-cta-size-select__powered-by-storer'
}

export const Attributes = {
    Price: 'data-price',
    PriceRaw: 'data-price-raw',
    Availability: 'data-availability',
    Size: 'data-size',
    SizeName: 'data-product-size',
    Ean: 'data-ean',
    AvailableSizes: 'data-available-sizes',
    ArticleId: 'value',
    SizeAvailable: 'data-size-available',
    DeliveryDate: 'data-delivery-date',
    ShopActive: 'data-shop-active'
}

const States = {
    AddToCartHidden: 'd-none',
    VendorContainerHidden: 'd-none'
}

export const SIZECHANGE_EVENTNAME = 'size:changed'
export const ADD_TO_CART_FORM_DISABLED_STATE = 'js-cart__form--disabled'

export let containerEl;
export let sizeRadioEls;
let productSizePriceEls;
let productSizePriceRawEls;
let productSizeTextEls;
let lastEventProductEan;
let colorVariantEls;
let addToCartFormEl;
let addToCartButtonEls;
let addToCartButtonInputEls;
let addToCartInfoEl;
let deliveryDateInfoEl;
let poweredByStorerEl;
let vendorContainerEls;

let addToCartFormSubmittable = false;

export function init () {
    onFind(Selectors.Container, (element) => {
        containerEl = element ? element : throwError("M26 Size Select JS – Container missing");
        sizeRadioEls = findAllIn(Selectors.SizeRadios, containerEl) ? findAllIn(Selectors.SizeRadios, containerEl) : throwError("M26 Size Select JS – Size options missing")
        productSizePriceEls = findAll(Selectors.SizePrice);
        productSizePriceRawEls = findAll(Selectors.SizePriceRaw);
        productSizeTextEls = findAll(Selectors.SizeText);
        colorVariantEls = findAll(Selectors.ColorVariants);
        addToCartFormEl = findIn(Selectors.AddToCartForm, containerEl);
        addToCartButtonEls = findAllIn(Selectors.AddToCartButton, containerEl);
        addToCartButtonInputEls = findAllIn(Selectors.AddToCartButtonArticleIdInput, containerEl);
        addToCartInfoEl = findIn(Selectors.AddToCartInfo, containerEl);
        deliveryDateInfoEl = findIn(Selectors.DeliveryDateInfo, containerEl);
        poweredByStorerEl = findIn(Selectors.PoweredByStorer, containerEl);
        vendorContainerEls = findAllIn(Selectors.VendorContainer, containerEl) ? findAllIn(Selectors.VendorContainer, containerEl) : console && console.warn && console.warn("M26 Size Select JS – No vendor containers");

        sizeRadioEls.forEach(sizeRadioEl => {
            sizeRadioEl.addEventListener('click', changeSize)
        });
    })

    document.addEventListener(SIZECHANGE_EVENTNAME, onSizeChanged)

    addToCartFormEl && addToCartFormEl.addEventListener('submit', event => { onAddToCartFormSubmit(event) })
}

function changeSize(event) {
    if (!event || !event.target || !event.target.value) return

    const changeEventData = {
        productPrice: event.target.getAttribute(Attributes.Price),
        productPriceRaw: event.target.getAttribute(Attributes.PriceRaw),
        productAvailability: event.target.getAttribute(Attributes.Availability),
        productSize: event.target.getAttribute(Attributes.Size),
        productSizeName: event.target.getAttribute(Attributes.SizeName),
        productEan: event.target.getAttribute(Attributes.Ean),
        articleId: event.target.getAttribute(Attributes.ArticleId),
        productSizeAvailable: event.target.getAttribute(Attributes.SizeAvailable),
        productDeliveryDate: event.target.getAttribute(Attributes.DeliveryDate),
        shopActive: event.target.getAttribute(Attributes.ShopActive)
    }


    // Don't trigger event if changeEventData product size EAN is unchanged
    if (lastEventProductEan === changeEventData.productEan) return

    triggerWith(SIZECHANGE_EVENTNAME, changeEventData, document);
    lastEventProductEan = changeEventData.productEan;
}

function onSizeChanged(event) {
    if (!event || !event.detail) return

    addToCartFormSubmittable = event.detail.productSizeAvailable === 'true' && event.detail.shopActive === 'true' ? true : false

    if (productSizePriceRawEls.length) {
        productSizePriceRawEls.forEach(productSizePriceRawEl => {
            productSizePriceRawEl.innerText = event.detail.productPriceRaw;
        });
    } else {
        console && console.warn && console.warn("M26 Size Select JS – No elements to change raw size price")
    }

    if (productSizePriceEls.length) {
        productSizePriceEls.forEach(productSizePriceEl => {
            productSizePriceEl.innerHTML = event.detail.productPrice;
        });
    } else {
        console && console.warn && console.warn("M26 Size Select JS – No elements to change size price")
    }

    if (productSizeTextEls.length) {
        productSizeTextEls.forEach(productSizeTextEl => {
            productSizeTextEl.innerText = event.detail.productSizeName;
        });
    } else {
        console && console.warn && console.warn("M26 Size Select JS – No elements to change size text")
    }

    if (event.detail.productSize) {
        colorVariantEls && updateSizeUrlOnColorVariants(event.detail.productSize)
        setSizeUrlParameter(event.detail.productSize);
    }

    if (deliveryDateInfoEl && event.detail.productDeliveryDate) {
        deliveryDateInfoEl.innerHTML = event.detail.productDeliveryDate;
    }

    if (!addToCartFormSubmittable) {
        addToCartFormEl && addToCartFormEl.classList.add(States.AddToCartHidden);
        addToCartInfoEl && addToCartInfoEl.classList.remove(States.AddToCartHidden);
        addToCartButtonEls.forEach(addToCartButtonEl => {
            addToCartButtonEl.setAttribute('disabled', 'disabled');
        });
        deliveryDateInfoEl && deliveryDateInfoEl.classList.add(States.AddToCartHidden);
        poweredByStorerEl && poweredByStorerEl.classList.add(States.AddToCartHidden);
    } else {
        addToCartFormEl && addToCartFormEl.classList.remove(States.AddToCartHidden);
        addToCartInfoEl && addToCartInfoEl.classList.add(States.AddToCartHidden);
        addToCartButtonEls.forEach(addToCartButtonEl => {
            addToCartButtonEl.removeAttribute('disabled');
        });
        deliveryDateInfoEl && deliveryDateInfoEl.classList.remove(States.AddToCartHidden);
        poweredByStorerEl && poweredByStorerEl.classList.remove(States.AddToCartHidden);
    }

    addToCartButtonEls.forEach(addToCartButtonEl => {
        addToCartButtonEl.dataset.cartProductId = event.detail.articleId;
    });

    addToCartButtonInputEls.forEach(addToCartButtonInputEl => {
        addToCartButtonInputEl.value = event.detail.articleId;
    });

    if (!vendorContainerEls.length) return

    if (!addToCartFormSubmittable) {
        vendorContainerEls.forEach((vendorContainerEl, index) => {
            removeClass(States.VendorContainerHidden, vendorContainerEl)

            if (index === 0) {
                setTimeout(() => {
                  vendorContainerEl.scrollIntoView({
                      behaviour: 'smooth',
                      block: 'start'
                  })
                }, 500)
            }
        });
    } else {
        vendorContainerEls.forEach(vendorContainerEl => {
            addClass(States.VendorContainerHidden, vendorContainerEl)
        });
    }
}

function updateSizeUrlOnColorVariants(size) {
    colorVariantEls.forEach(colorVariantEl => {
        updateSizeUrlOnColorVariant(colorVariantEl, size)
    });
}

function updateSizeUrlOnColorVariant(colorVariantEl, size) {
    const initialHref = colorVariantEl.getAttribute('href');
    const availableSizes = JSON.parse(colorVariantEl.getAttribute(Attributes.AvailableSizes));
    let sizeVariantHref = false

    for (var availableSize in availableSizes) {
        if (availableSizes.hasOwnProperty(availableSize)) {
            if (availableSizes[availableSize].sizeName === size) {
                sizeVariantHref = availableSizes[availableSize].detailLink + `?size=${size}`
            }
        }
    }

    colorVariantEl.setAttribute('href', sizeVariantHref !== false ? sizeVariantHref : initialHref);
}

function setSizeUrlParameter(size) {
    const url = new URL(window.location.href);
    url.searchParams.set('size', encodeURIComponent(String(size).toLowerCase()));

    window.history.pushState({ path: url.href }, '', url.href);
}

function onAddToCartFormSubmit(event) {
    if (!addToCartFormSubmittable) {
        event.preventDefault();
        return false;
    }
}
