"use strict";

/**
 * adjusts link titles for links with target=_href (WCAG), adds "opens in new window"
 */

import {translate} from "../translations/translations";
import {findAllIn} from "../findAllIn/findAllIn";

export async function init() {
    function _init() {
        addHintsForLinksOpeningNewTabs()
        setupObserver()
    }
    if (document.readyState !== 'loading') {
        _init()
    } else {
        document.addEventListener('DOMContentLoaded', () => _init());
    }
}

function addHintsForLinksOpeningNewTabs(rootElement = document.body) {
    const hintText = translate('link.opens-in-new-tab')
    findAllIn(rootElement, 'a[target=_blank]').forEach(link => {
        const oldTitle = link.title ? link.title.trim() : ''

        if (oldTitle.toLowerCase().includes(hintText.toLowerCase())) {
            return;
        }
        link.title = oldTitle ? oldTitle + ' (' + hintText + ')' : hintText;
    })
}

function setupObserver() {
    const observer = new MutationObserver(function (records) {
        records.forEach((record) =>
            record.addedNodes.forEach(addedNode =>
                addHintsForLinksOpeningNewTabs(addedNode)
            ))
    })

    observer.observe(document.body, {
        subtree: true,
        childList: true
    })
}