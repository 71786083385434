"use strict";

if (_config.registerPermissionGrantedCallback) {
    _config.registerPermissionGrantedCallback.forEach(function (obj) {
        registerPermissionGrantedCallback(obj.service, obj.callback);
    });
}

export function getServicePermission(serviceName) {
    return true;
}

export function registerPermissionGrantedCallback(serviceName, callback) {
    try {
        callback();
    } catch(error) {
        console.error(error);
    }
}

export function setServicePermission(serviceName, isAllowed) {
    
}


export function showModal() {
}

export function hideModal() {
    
}

export function showCookieBar() {
}

export function hideCookieBar() {
    try {
        localStorage.setItem('cookie-bar-dismissed', true);
    } catch (e) {}
}

export function onModalShow(cb) {
    
}