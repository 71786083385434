"use strict";
import * as wishlistbundle from "../libs/@elements/wishlist";
import {findAllIn, findAll, findIn, find, on, trigger, closest} from "../libs/@elements/dom-utils";
import {onFind} from "../libs/@elements/init-modules-in-scope";

let wishlist = null;
let clearNotification = 0;

let allLists = [];
if (window['_wishlist']) {
    allLists.push(wishlistbundle.createWishlist({
        ...window['_wishlist'].wishlist,
        notificationOptions: {
            container: findAll('.js-alert-notification'),
        }
    }))
}
if (window['_compareList']) {
    allLists.push({compareList: wishlistbundle.createWishlist({
            ...window['_compareList'].compareList,
            notificationOptions: {
                container: findAll('.js-compare__notification'),
                styleModifier: 'tes'
            },
            translations: {
                add: 'compareList.add',
                remove: 'compareList.remove'
            }
        }, {
            item: '.js-compare-list__btn',
            itemId: 'compare-list-id',
            wishlistId: 'compare-list-list',
            itemActiveClass: 'is-active'
        })
    })
}

export function init() {
    if (allLists.length) {
        allLists.forEach((wishlist) => {
            if (wishlist.compareList) {
                wishlist.compareList.init();
            } else {
                wishlist.init();
            }
        });
    }

    const renderWishlistHandler = (evt, params) => {
        if(params.list === "wishlist-list"){
            // update counter
            let wishlistItemCounter = find('.js-wishlist__items-count');
            if(params.activeIds && wishlistItemCounter){
                const count = params.activeIds.length;
                if(count <= 0) {
                    wishlistItemCounter.innerHTML= count;
                    wishlistItemCounter.setAttribute('hidden', 'hidden');
                }else {
                    wishlistItemCounter.innerHTML= count;
                    wishlistItemCounter.setAttribute('hidden', null);
                }
            }
            // activate navigation heart
            let wishlistHeader = find('.js-header__bookmark')
            if(params.activeIds.length && wishlistHeader) {
                wishlistHeader.classList.add('is-active');
            }
        }

        if(params.list === "compare-list-list"){
            if (params.activeIds) {
                const count = params.activeIds.length;

                if(count){
                    renderComparelistBadge(count);

                    if(count > 0) {
                        if (find('.js-compare-list')) {
                            setCellHeights(find('.js-compare-list'));
                        }
                    }
                }
            }
        }

    };
    on('renderd.wishlist', (evt) => renderWishlistHandler('params', evt.detail) , document);

    const addedWishlistHandler = (evt, params) => {
        if(params.list === "wishlist-list"){
            clearNotification++;

            // hide notification after some seconds
            setTimeout(function(){
                let notification = find('.js-alert-notification');
                if(clearNotification === 1 && notification){
                    notification.innerHTML = '';
                }

                clearNotification--;

            }, 3500);
        }

        if(params.list === "compare-list-list"){
            clearNotification++;

            // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    find('.js-alert-notification').innerHTML = '';
                }

                clearNotification--;

            }, 3500);
        }
    };
    on('added.wishlist', (evt) => addedWishlistHandler('params', evt.detail) , document);

    const removeWishlistHandler = (evt, params) => {
        if(params.list === "wishlist-list"){
            clearNotification++;

            // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    let notification = find('.js-alert-notification');

                    if(notification) {
                        notification.innerHTML = '';
                    }
                }

                clearNotification--;

            }, 3500);

            // check if navigation heart should be active
            let wishlistHeader = find('.js-header__bookmark')
            if(params.activeIds.length < 1 && wishlistHeader) {
                wishlistHeader.classList.remove('is-active');
            }
        }

        if(params.list === "compare-list-list"){
            let containerElement = find('.js-compare-product__container');
            if (containerElement) {
                trigger('submit', findIn('.js-ajax-form__form', containerElement));
            }

            renderComparelistBadge(params.activeIds.length);

            clearNotification++;

            // hide notification after some seconds
            setTimeout(function(){
                if(clearNotification === 1){
                    find('.js-alert-notification').innerHTML = '';
                }

                clearNotification--;

            }, 3500);
        }
    };
    on('removed.wishlist', (evt) => removeWishlistHandler('params', evt.detail) , document);

    onFind('.js-compare-list', function () {
        initInScope();
    });
}

function renderComparelistBadge(count) {
    let compareListLink = find('.js-compare-list__link');
    let compareListItemCounter = find('.js-compare-list__items-count');

    if (compareListItemCounter) {
        if(count <= 0) {
            compareListItemCounter.innerHTML= count;
            compareListItemCounter.setAttribute('hidden', 'hidden');
            compareListLink.setAttribute('hidden', 'hidden');
        }else {
            compareListItemCounter.innerHTML= count;
            compareListItemCounter.removeAttribute('hidden');
            compareListLink.removeAttribute('hidden');

            if (find('.js-compare-list')) {
                setCellHeights(find('.js-compare-list'));
            }
        }
    }
}

export function initInScope(scope = find('body') ) {
    if (allLists.length) {
        allLists.forEach((wishlist) => {
            let isCompareList = !!wishlist.compareList;
            wishlist = wishlist.compareList ? wishlist.compareList : wishlist;
            wishlist.initInScope(scope);

            let wishListContainer = findAllIn('.js-wishlist__container',scope);

            wishListContainer.forEach((el) => {
                const container = el,
                    button = findIn('.js-wishlist__remove',container),
                    id = container.getAttribute('data-wishlist-id');

                const buttonClickHandler = (evt, button) => {
                    evt.preventDefault();
                    if(button.classList.contains('clicked')) {
                        return;
                    }
                    button.classList.add('clicked');
                    let _request = wishlist.remove(id);

                    if (_request) {
                        _request.then(() => {
                            container.remove()
                        })
                    }
                };

                button.addEventListener('click', (evt) => buttonClickHandler(evt, button));

                return {
                    cleanUp: () => {
                        button.removeEventListener('click', (evt) => buttonClickHandler(evt, button));
                    }
                };
            });

            if (window['_compareList'] && isCompareList) {
                let compareListContainer = findAllIn('.js-compare-product__container',scope);

                compareListContainer.forEach((el) => {
                    const container = el,
                        buttons = findAllIn('.js-compare-list__remove', container);

                    const buttonClickHandler = (evt, button) => {
                        evt.preventDefault();
                        wishlist.remove(button.getAttribute('data-compare-list-id'), {showUndo: true});
                    };

                    buttons.map((button) => {
                        button.addEventListener('click', (evt) => buttonClickHandler(evt, button));

                        return {
                            cleanUp: () => {
                                button.removeEventListener('click', (evt) => buttonClickHandler(evt, button));
                            }
                        };
                    });
                });
            }
        });
    }
}

export function setCellHeights(compareList) {
    let colHeights = {};
    findAllIn('.js-compare-list__col', compareList).map((colItem) => {
        let colName = colItem.getAttribute('data-col-name');
        if (colHeights[colName]) {
            colHeights[colName].push(colItem.getBoundingClientRect().height)
        } else {
            colHeights[colName] = [colItem.getBoundingClientRect().height];
        }

        let collapseParent = closest('.collapse', colItem);
        if (collapseParent) {
            collapseParent.addEventListener('shown.bs.collapse', function () {
                setCellHeights(closest('.js-compare-list', colItem));
            });
        }
    });

    let rowHeights = [];
    Object.keys(colHeights).forEach((item) => {
        rowHeights.push({name: item, height: Math.max(...colHeights[item])})
    });

    rowHeights.map((item) => {
        findAllIn('.js-compare-list__col' + '[data-col-name="'+ item.name +'"]', compareList).map((col) => {
            col.style.minHeight = item.height + 'px';
        });
    })
}


init();
initInScope(find('body'));