"use strict";

import {findIn, findAllIn, on, addClass, hasClass, removeClass} from "../libs/@elements/dom-utils";
import {onFind} from "../libs/@elements/init-modules-in-scope";
import Collapse from 'bootstrap/js/dist/collapse';
import initModulesInScope from '../libs/@elements/init-modules-in-scope';

export function init() {
    onFind('.js-collapse-row', function (row) {
        let collapseToggles = findAllIn('.js-collapse-row__toggle', row);

        collapseToggles.map((element) => {
            on('click', function () {
                let template = findIn('.js-collapse-row__content-template', element);
                let target = findIn(element.getAttribute('data-bs-target'), row);

                if (matchMedia('(max-width: 767px)').matches) {
                    target = findIn(element.getAttribute('data-bs-target-xs'), row);
                }

                if (hasClass('is-active', element)) {
                    removeClass('is-active', element);
                    Collapse.getInstance(target).hide();
                } else {
                    findIn('.js-collapse-row__content', target).innerHTML = template.innerHTML;

                    if (matchMedia('(min-width: 768px)').matches) {
                        collapseToggles.map((element) => {removeClass('is-active', element)});
                    }
                    addClass('is-active', element);
                    Collapse.getInstance(target).show();
                }

                initModulesInScope(findIn('.js-collapse-row__content', target));
            }, element);
        });
    });
}