"use strict";

import {add} from '../libs/@elements/scroll-animations';
import {findAll} from "../libs/@elements/dom-utils";

export function init() {
    add(
        findAll('.js-fade-in'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 10 * 9;
    const end = window.innerHeight / 1.5;

    return {
        opacity: 1 - Math.max(
            Math.min(
                (element.getBoundingClientRect().top - end) / (start - end),
                1
            ),
            0
        )

    }

}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    element.style.opacity = progress.opacity;
}