"use strict";

/**
 * JS fetch with a cache
 **/

const TYPE_JSON = 'json';

const _items = {}

class CachedFetches {
    add(name, url, config = {}) {
        _items[name] = {
            name: name,
            url: url,
            config: config,
            type: TYPE_JSON,
            value: undefined,
            current_promise: undefined,
        };
    }

    async get(name, bypassCache = false) {
        if (typeof _items[name] === 'undefined') {
            throw new Error('cached fetch with name '+name+' is not defined');
        }
        const item = _items[name];

        if (bypassCache || typeof item.value === 'undefined') {
            if (!item.current_promise) {
                item.current_promise = this.__fetch_with_fallback_value(item)
            }

            await item.current_promise
            return item.value //don't return the current_promise, it will be overwritten by undefined (race condition)
        }

        return item.value
    }

    /**
     * on fail: logs error, keeps the old cached value as fallback value
     * on success: sets the new cached value
     */
    async __fetch_with_fallback_value(item) {
        return this.__fetch(item).then(result => {
            item.value = result;
        }).catch(e => {
            console.error('Error in cached-fetches: ', e)
        }).finally(() => {
            item.current_promise = undefined
            return item.value
        });
    }

    /**
     * execute the fetch, do some error handling
     */
    async __fetch(item) {
        return new Promise((resolve, reject) => {
            fetch(item.url, item.config).catch(() => {
                reject('cached fetches: could not fetch ' + item.url);
            }).then((response) => item.type === TYPE_JSON ? response.json() : response).catch(() => {
                reject('cached fetches: could not parse JSON response of ' + item.url);
            }).then((data) => {
                resolve(data);
            })
        })
    }

    list() {
        return Object.keys(_items);
    }

    /** init by global catchedFetchesConfig object */
    init() {
        if (window['cachedFetchesConfig'] === undefined) {
            return this;
        }
        for (const [cachedItemName, _c] of Object.entries(window['cachedFetchesConfig'])) {
            cachedFetches.add(cachedItemName, _c.url, _c.config);
            if (_c.initialFetch) {
                cachedFetches.get(cachedItemName).then(() => {
                });
            }
        }
        return this;
    }
}

export const cachedFetches = new CachedFetches();