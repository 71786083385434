'use strict'
import { MenuAim } from 'es6-menu-aim';
import {find, findIn, findAllIn, siblings} from "../libs/@elements/dom-utils";
import * as activitiesSlider from './sr3-flyout/activities-slider';
import * as navSliderMobile from './sr3-flyout/nav-slider-mobile';
import * as navSliderDesktop from './sr3-flyout/nav-slider-desktop';

let _isMobileNav, _backdropActive, _navOpen, _searchOpen, _cartOpen, _accountOpen;

let headerNav, mainNav, menuAim;
let searchBtn, cartBtn, accountBtn, navItems, mobileNavBtn, mobileBackBtns;
let searchDropdown, cartDropdown, accountDropdown, navDropdowns, navItems_sub, subMenus;
let navBar;
let closeBtn;
let activitiesSliders, navSliders;
let cartContainer, accountContainer;

export const init = () => {
    const scope = find('.header__nav');
    if (!scope) return;
    headerNav           = scope;
    mainNav             = findIn('#main-nav', scope);

    searchBtn           = findIn('.js-header__searchBtn', scope);
    cartBtn             = findIn('.js-header__cartBtn', scope);
    accountBtn          = findIn('.js-header__accountBtn', scope);
    navItems            = findAllIn('.js-header__navItem', scope);
    navItems_sub        = findAllIn('.js-header__has-submenu', scope);
    mobileNavBtn        = findIn('.js-header__opennav', scope);
    mobileBackBtns      = findAllIn('.js-header__submenu-heading-back', scope);

    searchDropdown      = findIn('.js-header__searchDropdown', scope);
    cartDropdown        = findIn('.js-header__cartDropdown', scope);
    accountDropdown     = findIn('.js-header__accountDropdown', scope);
    navDropdowns        = findAllIn('.js-header__navDropdown', scope);
    navBar              = findIn('.js-header__navbar', scope);
    closeBtn            = findIn('.js-header__dropdown-close', scope);
    subMenus            = findAllIn('.js-header__submenu', scope);

    cartContainer       = findIn('.js-cart-dropdown__container', scope);
    accountContainer    = findIn('.js-account-dropdown__container', scope);

    activitiesSliders    = findAllIn('.mai__nav-activities', scope);
    navSliders           = findAllIn('.mai__nav-slider', scope);

    const breakPoint = window.matchMedia('(max-width: 1199px)');

    /** bind mobile / desktop nav switches on matchmedia events **/
    if (document.documentMode || /Edge/.test(navigator.userAgent) || (/iPad|iPhone|iPod/.test(navigator.userAgent) || navigator.userAgent.indexOf("Safari") !== -1 && !window.MSStream)) {
        breakPoint.addListener((e) => resizeHandler(e)) //ie fallback
    }else{
        breakPoint.addEventListener("change", resizeHandler)
    }

    resizeHandler(breakPoint);

    /** init activities slider **/
    activitiesSliders.forEach((element) => {
        activitiesSlider.init(element);
    });

    /** Bind NavButton Actions**/
    bindNavButtons();
}

function resizeHandler(breakPoint) {
    const desktopModifier = 'mai__nav-slider--desktop';

    if(breakPoint.matches){
        initMobileNav();
        /** init nav sliders */
        navSliders.forEach((element) => {
            element.classList.remove(desktopModifier);
            navSliderMobile.init(element);
        });
    }else{
        initDesktopNav();
        /** init nav sliders */
        navSliders.forEach((element) => {
            element.classList.add(desktopModifier);
            navSliderDesktop.init(element);
        });
    }
}

function initMobileNav() {
    closeAll();
    if(menuAim instanceof MenuAim) {
        menuAim.detach();
    }
    navItems.forEach((element) => {
        element.removeEventListener('touchend', touchendDesktop);
    });

    navItems_sub.forEach((element) => {
        element.addEventListener('click', clickMobile);
    });

    closeBtn && closeBtn.addEventListener('click', (e) => {
        e.preventDefault();
        closeAll(e)
    })

    mobileBackBtns.forEach((element) => {
        element.addEventListener('click', (e) => {
            let submenu = e.currentTarget.closest('.js-header__submenu');
            submenu.classList.remove('show');
            if(submenu.parentNode.parentNode.id === 'main-nav') {
                navBar.classList.remove('overflow-hidden');
                navBar.scrollTop = 0;
            }
        })
    });

    _isMobileNav = true;
}

function initDesktopNav() {
    closeAll();
    navItems_sub.forEach((element) => {
        element.removeEventListener('click', clickMobile);
    });
    if(!(menuAim instanceof MenuAim )) {
        menuAim = new MenuAim(mainNav, {
            activate: (li) => {
                if(matchMedia('(max-width: 1199px)').matches){
                    return
                }

                const hasSubmenu = findIn('.js-header__has-submenu', li);

                if (!hasSubmenu) {
                    closeAll();
                    return;
                }

                openDropdown(findIn('a', li));
            },
            deactivate: (li) => {
                if(matchMedia('(max-width: 1199px)').matches){
                    return
                }

                const hasSubmenu = findIn('.js-header__has-submenu', li);

                if (!hasSubmenu) {
                    closeAll();
                    return;
                }

                closeDropdown(findIn('a', li));
            },
            exitMenu: () => {
                if(matchMedia('(max-width: 1199px)').matches){
                    return false;
                }
                closeNavigation();
                return true;
            },
            submenuDirection: "below",
            tolerance: 1,
            isRoot:true
        });

        mainNav.children.forEach((element) => {
            menuAim.hookUp(element);
        })
    }

    closeBtn && closeBtn.addEventListener('click', (e) => {
        closeAll(e)
    })

    navItems.forEach((element) => {
        element.addEventListener('touchend', touchendDesktop);
    });

    _isMobileNav = false;
}

function clickMobile(e) {
    const elemSiblings = siblings(e.currentTarget).filter((sibling) => {
        return sibling.classList.contains('js-header__submenu');
    });

    elemSiblings.forEach((sibling) => {
        sibling.classList.add('show');
    });

    navBar.scrollTop = 0;
    navBar.classList.add('overflow-hidden');

    e.stopPropagation();
    e.preventDefault();
}

function touchendDesktop(e) {
    const elemSiblings = siblings(e.currentTarget).filter((sibling) => {
        return sibling.classList.contains('js-header__navDropdown');
    });

    if(elemSiblings.length){
        openDropdown(e.currentTarget);
        e.preventDefault();
    }else{
        closeAll();
    }
}

function bindNavButtons() {
    searchBtn.addEventListener('click', () => {
        if(_searchOpen){
            closeAll();
        }else{
            openSearch();
        }
    });
    cartBtn && cartBtn.addEventListener('click', () => {
        if(_cartOpen){
            closeAll();
        }else{
            openCart();
        }
    });
    accountBtn && accountBtn.addEventListener('click', () => {
        if(_accountOpen){
            closeAll();
        }else{
            openAccount();
        }
    });
    mobileNavBtn.addEventListener('click',()  => {
        if(_navOpen){
            closeAll()
        }else{
            openNavigation()
        }
    })

    document.addEventListener('click', (event) => {
        if (!event || !event.target) return false

        if (_cartOpen) {
            if (event.target !== cartBtn &&
                !event.target.closest('.js-header__cartBtn') &&
                event.target !== cartContainer &&
                !event.target.closest('.js-cart-dropdown__container')) {
                closeAll();
            }
        }

        if (_accountOpen) {
            if (event.target !== accountBtn &&
                !event.target.closest('.js-header__accountBtn') &&
                event.target !== accountContainer &&
                !event.target.closest('.js-account-dropdown__container')) {
                closeAll();
            }
        }
    })
}

export function openNavigation() {
    if(_navOpen){
        return
    }
    closeAll();
    showBackdrop();
    headerNav.classList.add('open');
    navBar.classList.add('show');
    mobileNavBtn.classList.add('show');

    _navOpen = true;
}

export function closeNavigation() {
    /** desktop classes **/
    navItems.forEach((element) => {
        element.classList.remove('show');
    })
    navDropdowns.forEach((element) => {
        element.classList.remove('show');
    })

    /** mobile classes **/
    headerNav.classList.remove('open');
    navBar.classList.remove('show');
    subMenus.forEach((element) => {
        element.classList.remove('show');
    })
    mobileNavBtn.classList.remove('show');

    if(!_searchOpen && !_cartOpen && !_accountOpen){
        removeBackdrop();
    }
    _navOpen = false;
}
export function closeAll() {
    /** general classes **/
    searchDropdown.classList.remove('show');
    searchBtn.classList.remove('show');
    cartDropdown && cartDropdown.classList.remove('show');
    cartBtn && cartBtn.classList.remove('show');
    accountDropdown && accountDropdown.classList.remove('show');
    accountBtn && accountBtn.classList.remove('show');

    /** desktop classes **/
    navItems.forEach((element) => {
        element.classList.remove('show');
    })
    navDropdowns.forEach((element) => {
        element.classList.remove('show');
    })

    /** mobile classes **/
    headerNav.classList.remove('open');
    navBar.classList.remove('show');
    subMenus.forEach((element) => {
        element.classList.remove('show');
    })
    mobileNavBtn.classList.remove('show');

    removeBackdrop();

    _navOpen = false;
    _searchOpen = false;
    _cartOpen = false;
    _accountOpen = false;
}

function showBackdrop() {
    if(_backdropActive){
        return;
    }
    document.querySelector('body').insertAdjacentHTML("beforeend",'<div class="header__backdrop fade"></div>')
    setTimeout(() => {
        const backdrop = document.querySelector('.header__backdrop');
        if(backdrop instanceof Element) {
            backdrop.classList.add('show');
            backdrop.addEventListener('click', () => {
                closeAll();
            })
        }
    }, 0);
    _backdropActive = true;
}

function removeBackdrop() {
    if(!_backdropActive){
        return
    }
    const backdrop = document.querySelector('.header__backdrop');
    backdrop.parentNode.removeChild(backdrop);
    _backdropActive = false;
}

function closeDropdown(target) {
    if(target.classList.contains('show')) {
        const elemSiblings = siblings(target).filter((sibling) => {
            return sibling.classList.contains('js-header__navDropdown');
        });

        target.classList.remove('show');
        elemSiblings.forEach((element) => {
            element.classList.remove('show');
        });
        _navOpen = false;
    }
}

function openDropdown(target) {
    if(_searchOpen || _cartOpen || _accountOpen){
        return;
    }
    if(target.classList.contains('show')){
        closeAll();
        return
    }

    const elemSiblings = siblings(target).filter((sibling) => {
        return sibling.classList.contains('js-header__navDropdown');
    });

    if(siblings.length) {
        showBackdrop();
        target.classList.add('show');
        elemSiblings.forEach((element) => {
            element.classList.add('show');
        })

        _navOpen = true;
    }else{
        removeBackdrop()
    }
}

export function openCart() {
    if(_cartOpen){
        return;
    }
    closeAll();

    cartBtn && cartBtn.classList.add('show');
    cartDropdown && cartDropdown.classList.add('show');
    showBackdrop();

    _cartOpen = true;
}

export function openAccount() {
    if(_accountOpen){
        return;
    }
    closeAll();

    accountBtn && accountBtn.classList.add('show');
    accountDropdown && accountDropdown.classList.add('show');
    showBackdrop();

    _accountOpen = true;
}

export function openSearch() {
    if(_searchOpen){
        return;
    }
    closeAll();

    searchBtn.classList.add('show');
    //searchDropdown.classList.add('show').trigger('search:shown');
    searchDropdown.classList.add('show');
    searchDropdown.dispatchEvent(new Event('search:shown'));
    showBackdrop();
    //focus in search
    const searchInput = document.querySelector('.js-typeahead input.react-select__input');
    if (searchInput) {
        searchInput.focus();

        const inputContainer = document.querySelector('.js-typeahead .react-select__value-container');
        inputContainer.addEventListener('click', () => {
            searchInput.focus();
        });
    }

    _searchOpen = true;
}
