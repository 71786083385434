import $ from "jquery";

import 'bootstrap/js/dist/modal';

let _config = window['_config'] || {};

let appRaffle = {
    debug: false,
};
window.appRaffle = appRaffle || {};
window.appRaffle.modules =  appRaffle.modules || {};

import initModulesInScope from "./libs/@elements/init-modules-in-scope";
appRaffle.initModules = function ($scope) {
    console.warn('\'appRaffle.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('./libs/@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as customFile from './scripts/customFile';
appRaffle.modules.customFile = customFile.initInScope;

import * as friendlyCaptchaV1 from './scripts/friendlyCaptchaV1';
appRaffle.modules.friendlyCaptchaV1 = friendlyCaptchaV1.initInScope;

import * as parsley from './libs/@elements/parsley-bootstrap-validation';
appRaffle.modules.parsley = parsley.initInScope;

import * as raffle from './scripts/raffle';
appRaffle.raffle = raffle.init;
appRaffle.modules.raffle = raffle.initInScope;

import * as floatingLabel from './libs/@elements/floating-labels';
appRaffle.modules.floatingLabel = floatingLabel.initInScope;

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof appRaffle[_key] == 'function' ){
                appRaffle[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof appRaffle[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            appRaffle[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof appRaffle[_key] == 'function' ) ){
                appRaffle[_key]();
            }
        });
    }

    initModulesInScope();
})($);
