'use strict'
import {removeAttribute, setAttribute, find, findIn, on, createElementsByHTML} from "../libs/@elements/dom-utils";
import {onFind} from "../libs/@elements/init-modules-in-scope";
import initModulesInScope from '../libs/@elements/init-modules-in-scope';
import { showNotification } from "../libs/@elements/alert-notification";
import throwError from "../libs/@elements/throw-error";
import { translate } from '../../shared/translations/translations'
import formDataEntries from 'form-data-entries';
import fetch from "../libs/@elements/fetch"
import 'url-search-params-polyfill';
import {createFormValidation} from "./form-validation";

let pendingRequest;

export function init() {
    onFind('.js-login-form', function(form){
        //let formToValidate = findIn('.js-form-validation', form);
        let redirectHref = findIn('.js-ajax-login__href', form);
        let loading = findIn('.js-login-form__loading', form);

        onFind('.js-ajax-login__set-href', function (link) {
            on('click', function (e) {
                let href = this.getAttribute('href');
                redirectHref.value = href;
            }, link)
        }, form);

            const pristine = createFormValidation(form);
            form.addEventListener('submit', function (e) {
                if (pendingRequest) {
                    pendingRequest.abort();
                    pendingRequest = null;
                }

                e.preventDefault();

                if (!pristine.validate()){
                    //console.log('not valid')
                } else {
                    let formData = formDataEntries(form);
                    let params = new URLSearchParams(formData);
                    let url = form.getAttribute('data-action') || throwError(translate('login.no.action.url'))

                    removeAttribute('hidden', loading);

                    pendingRequest = fetch(url, {
                        method: 'POST',
                        body: params
                    });
                    pendingRequest
                        .then((res) => {
                            return res.clone().json()
                        }).then((res) => {

                        if (res.success) {
                            const data = res.data;
                            if (data.loginstate) {
                                window.location.href = data.url || throwError(translate('login.no.url.given'));
                            } else {
                                let html = data.html || throwError(translate('login.no.html.given'))
                                let newForm = createElementsByHTML(html)[0];

                                form.replaceWith(newForm);
                                setAttribute('hidden', true, loading);
                                initModulesInScope(newForm.parentNode);
                            }
                        }
                    }).catch((error) => {
                        if (error.name !== 'AbortError') {
                            /*Do error stuff*/
                            console.error(error);
                        }
                        setAttribute('hidden', true, loading);
                    });
                    showNotification(pendingRequest);
                }

            });

    });

}