"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement) {
    const selectors = {
        slide: '.splide__slide',
        youtubeIframe: 'iframe.mai__base-video__ytvideo',
    };

    const splideOptions = {
        type: 'slide',
        focus: 'center',
        arrows: false,
        drag: true,
        mediaQuery: 'min',
        classes: {
            pagination: 'mai__m14-gallery__pagination splide__pagination mai__slider-pagination',
            arrows: 'mai__m14-gallery__slider-arrows mai__slider-arrows splide__arrows',
            arrow : 'splide__arrow mai__slider-arrow',
        },
        perPage: 1,
        breakpoints: {
            992: {
                arrows: true
            }
        }
    }

    slider = new Splide( sliderElement, splideOptions ).mount();
    const slides = Array.prototype.slice.call(sliderElement.querySelectorAll(selectors.slide));

    slider.on('move', (newIndex, oldIndex) => {
        moveSlideHandler(newIndex, oldIndex)
    });

    function moveSlideHandler(newIndex, oldIndex) {
        const lastSlide = slides[oldIndex] || null;
        const video = getVideoDOM(lastSlide);

        if(!lastSlide || video === undefined) {
            return;
        }

        if(video instanceof HTMLIFrameElement) {
            sendYTVideoCommand(video, 'pauseVideo');
        }

        if(video instanceof HTMLVideoElement) {
            video.pause();
        }
    }

    function getVideoDOM(slide) {
        let video = undefined;
        if(slide.querySelector('video')) {
            video = slide.querySelector('video');
        }

        if(slide.querySelector(selectors.youtubeIframe)) {
            video = slide.querySelector(selectors.youtubeIframe);
        }

        return video;
    }

    function sendYTVideoCommand(iframe, func, args = []) {
        if(iframe) {
            iframe.contentWindow.postMessage(JSON.stringify({
                "event": "command",
                "func": func,
                "args": args
            }), "*");
        }
    }
    
}




