"use strict";

import {toHTMLElementArray} from "../dom-utils";

let subscriptions = [];
let impressionInterval;
let defaultSettings = {
    offset: 0
};

export function onEnterViewPort(newElements, callback, settings) {
    newElements = toHTMLElementArray(newElements);

    newElements.forEach(function (element) {
        subscriptions.push({
            element: element,
            callback: callback,
            settings: {
                ...defaultSettings,
                ...settings
            }
        });
    });

    if (!impressionInterval) {
        impressionInterval = setInterval(function () {
            subscriptions = subscriptions.filter(function (subscription) {
                return isElementInDom(subscription.element);
            });

            let inViewport = subscriptions.filter(function (subscription) {
                return isElementInViewport(subscription.element, subscription.settings.offset);
            });

            subscriptions = subscriptions.filter(function (subscription) {
                return !isElementInViewport(subscription.element, subscription.settings.offset);
            });

            inViewport.forEach(function (subscription) {
                subscription.callback.call(subscription.element, subscription.element);
            });

            if (subscriptions.length === 0) {
                clearInterval(impressionInterval);
                impressionInterval = null;
            }
        }, 200);
    }
}

export function isElementInViewport(element, offset = 0) {
    let rect = element.getBoundingClientRect();

    if (!isElementVisible(element)) {
        return false;
    }

    return (
        rect.bottom + offset >= 0 &&
        rect.top - offset <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.left <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );
}

function isElementVisible(element) {
    let rect = element.getBoundingClientRect();

    return !(rect.top === 0 &&
        rect.bottom === 0 &&
        rect.height === 0 &&
        rect.width === 0
    );
}

function isElementInDom(element) {
    return document.body.contains(element);
}