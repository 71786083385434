/**
 * find elements in scope:
 * const eachElementInScope = createForEachElementInScopeFunc('.js-mymodule')
 * eachElementInScope(myContainer, doSomethingWithElement)
 *
 * find elements in the whole document:
 * const eachElementInDocument = createForEachElementInDocumentFunc('.js-mymodule')
 * eachElementInDocument(doSomethingWithElement)
 */

export const createForEachElementInScopeFunc = selector => (scope, func) => {
    const myscope = scope || document.body;
    return myscope.querySelectorAll(':scope ' + selector).forEach(el => func(el));
};

export const createForEachElementInDocumentFunc = selector => (func) => createForEachElementInScopeFunc(selector)(undefined, func)