/**
 * small helper to keep track of initialization status of JS-elements
 */

const INITIALIZATION_STATUS__DATA_KEY = 'data-is-initialized'
const INITIALIZATION_STATUS__DATA_KEY__SELECTOR = '[' + INITIALIZATION_STATUS__DATA_KEY + ']'

export function isInitialized(element) {
    return element.getAttribute(INITIALIZATION_STATUS__DATA_KEY)
}

export function setAsInitialized(element) {
    element.setAttribute(INITIALIZATION_STATUS__DATA_KEY, 1)
}

export function setAsNotInitialized(element) {
    element.removeAttribute(INITIALIZATION_STATUS__DATA_KEY);
}

//in case the elements have been copied and need to be "re"-initializised
export function setAllAsNotInitialized(container) {
    if (!container) {
        return
    }

    [
        ...container.querySelectorAll(':scope ' + INITIALIZATION_STATUS__DATA_KEY__SELECTOR),
        container
    ].forEach(element => setAsNotInitialized(element))
}