"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement) {
    const splideOptions = {
        type: 'loop',
        cover: true,
        arrows: false,
        perPage: 1,
        mediaQuery: 'min',
        padding: { left: '1.25rem', right: '1.25rem' },
        gap: '0.625rem',
        breakpoints: {
            992: {
                padding: { left: '2.5rem', right: '2.5rem' },
                gap: '1.25rem',
                perPage: 3,
                perMove: 1,
                arrows: true,
            }
        },
        pagination: false,
        classes: {
            arrows: 'mai__m52-social-wall__arrows mai__slider-arrows splide__arrows',
            arrow : 'splide__arrow mai__slider-arrow',
        }
    }

    slider = new Splide( sliderElement, splideOptions ).mount();
}
