"use strict";

export function setActiveRootMenu() {
    if (typeof window.activeRootMenuId === 'undefined') {
        console.error('no active root menu id ')
        return
    }

    if (!window.activeRootMenuId) {
        return;
    }

   document.querySelectorAll('[data-page-id="' + window.activeRootMenuId + '"]').forEach(
        el => {
            el.classList.add('is-active');
        }
    )
}
