"use strict";

import 'url-search-params-polyfill'; // Edge Polyfill
import 'whatwg-fetch'; // IE10 Polyfill
import {createAjaxForm} from '../ajax-form';
import { findIn } from '../dom-utils'
import fetch from '../fetch';
import formDataEntries from 'form-data-entries';

const defaultSelectors = {
    base: '.js-ajax-form-map',
    result: '.js-ajax-form-map__result',
    loading: '.js-ajax-form-map__loading',
    notifications: '.js-ajax-form-map__notifications',
    form: '.js-ajax-form-map__form',
    additionalForm: '.js-ajax-form-map__additional-form',
    errorArea: '.js-ajax-form-map__error-area',
    retry: '.js-ajax-form-map__retry',
    link: '.js-ajax-form-map__link'
};

export const createInitAjaxFormMap =({createMap, getApi}) => {
    return function (form, options, selectors = defaultSelectors) {
        let formObj = {
            mapObj: createMap(options.element, options),
            element: form,
            formApi: createAjaxForm(form, {
                onSubmit: x => fetchPois()
            }, selectors)
        };

        getApi(options.element).then((api) => {
            fetchPois(api);
        })

        async function fetchPois(api) {
            if(!api){
                api = await getApi(options.element);
            }

            fetch(formObj.element.dataset.formMapUrl, {
                method: 'GET',
                headers: {
                    ajax: 1 // add ajax header to differentiate between and ajax requests and page request.
                    // If this header is not set, browser back could lead the browser to use the cached result of this request as page response.
                },
                body: new URLSearchParams(formDataEntries(findIn('form', form)))
            }).then((res) => {
                return res.json()
            }).then((res) => {
                if (res.success) {
                    api.setMarker(res.pois);
                }
            }).catch((error) => {
                console.error("loading data-form-map-url failed:" + error);
            });
        }

        return formObj;
    }
};