"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement) {
    const splideOptions = {
        type: 'slide',
        focus: 'center',
        gap: '2.5rem',
        mediaQuery: 'min',
        classes: {
            pagination: 'splide__pagination mai__slider-pagination'
        }
    }

    slider = new Splide( sliderElement, splideOptions ).mount();
}
