'use strict'

import {onFind} from "../libs/@elements/init-modules-in-scope";
import {findIn, findAllIn} from "../libs/@elements/dom-utils";

const selectors = {
    langModal: '.js-lang-modal',
    langButtons: '.js-lang-modal__btn',
    langButtonClass: '.js-lang-modal__btn',
    collapseClass: '.js-lang-modal__collapse'
}

export function init() {
    let langModal;
    onFind(selectors.langModal, (element)=>{
        langModal = element ? element : null;
        if (langModal !== null) {
            initLangDropdowns(langModal);
        }
    });
}

function initLangDropdowns(modal){
    const langButtons = findAllIn(selectors.langButtons, modal);

    if(langButtons.length) {
        langButtons.forEach((element)=>{
            element.addEventListener('click', (event) => {
                event.preventDefault();
                const
                    target         = event.target,
                    button         = target.classList.contains(selectors.langButtonClass) ? target : target.closest(selectors.langButtonClass),
                    collapses      = findIn(selectors.collapseClass, modal),
                    targetCollapse = button.nextElementSibling.matches(selectors.collapseClass) ? button.nextElementSibling : null;
                    collapses.classList.remove('is-active');
                    targetCollapse.classList.add('is-active');
            }, false);
        })
    }
}