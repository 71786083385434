"use strict";

import Splide from '@splidejs/splide';

export let slider;
export let navSlider;

export function init (sliderElement, navSliderElement) {
    const splideOptions = {
        perPage: 1,
        perMove: 1,
        arrows: false,
        pagination: false,
        type: 'fade',
        drag: false
    }

    if (navSliderElement) {
        const navSplideOptions = {
            isNavigation: true,
            arrows: false,
            pagination: false,
            type: 'slide',
            drag: false,
            mediaQuery: 'min',
            gap: '0.625rem',
            breakpoints: {
                992: {
                    gap: '0.9375rem'
                }
            }
        }
        slider = new Splide( sliderElement, splideOptions );
        navSlider = new Splide( navSliderElement, navSplideOptions );

        slider.sync( navSlider );
        slider.mount();
        navSlider.mount();

    } else {
        slider = new Splide( sliderElement, splideOptions ).mount();
    }
}
