"use strict";

import {onFind} from "../init-modules-in-scope";
import {on} from "../dom-utils";

export function init () {
    onFind('.js-action-changer', function (baseElement) {
        on('submit', function() {
            let newAction = baseElement.getAttribute("data-action");
            if (newAction) {
                baseElement.action = newAction;
            } else {
                baseElement.action = '';
            }
            console.log(baseElement.action); 
        }, baseElement);
    });
}