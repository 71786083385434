"use strict";

import {find,addClass,removeClass, hasClass} from "../libs/@elements/dom-utils";
import {isScrollLock} from "../libs/@elements/scroll-lock";

let offset,
    nav,
    navHeight;

export function init () {
    nav = find('.js-affix-nav');
    const navPlaceholder = find('.js-affix-nav-placeholder');

    if(navPlaceholder) {
        navHeight = nav.offsetHeight;

        let scrollPos = window.pageYOffset;
        offset = navHeight - nav.offsetTop;

        const addClassOnScroll = () => addClass('is-affix', nav);
        const removeClassOnScroll = () => removeClass('is-affix', nav);

        if (!hasClass('js-affix-nav-placeholder--no-height', navPlaceholder)) {
            navPlaceholder.style.minHeight = nav.getBoundingClientRect().height.toString() + "px";
        }

        window.addEventListener('scroll', function() {
            scrollPos = window.pageYOffset;

            if (scrollPos === 0 && !isScrollLock()) {
                removeClassOnScroll();
            } else {
                if (scrollPos >= navHeight - offset) {
                    addClassOnScroll();
                } else {
                    if (!isScrollLock()) {
                        removeClassOnScroll();
                    }
                }
            }
        }, false);
    }

}

export function updateNavOffset () {
    offset = navHeight - nav.offsetTop;
}