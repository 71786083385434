"use strict";

import {onFind} from "../libs/@elements/init-modules-in-scope";
import Modal from "bootstrap/js/dist/modal";
import {findIn} from "../libs/@elements/dom-utils";
import fetch from "../libs/@elements/fetch";

const showAfterSeconds = 1;

export function init() {
    if(document.referrer !== '') {
        return;
    }

    fetch(`${window.location.href}?render-country-mismatch-popup=1&${Date.now()}`).then((res) => {
        return res.clone().json();
    }).then((res) => {
        if (res.html) {
            onFind('.js-lightbox-container', function (lightboxContainer) {
                lightboxContainer.insertAdjacentHTML('beforeend', res.html)
                const countryMismatchPopupEl = lightboxContainer.getElementsByClassName('modal--country-mismatch-popup')[0]
                const countryMismatchModal = Modal.getOrCreateInstance(countryMismatchPopupEl, {
                    keyboard: false
                });
                setTimeout(() => {
                    countryMismatchModal.show();
                }, showAfterSeconds * 1000)

                const selectAllBtn = findIn('.js-countryMismatchPopup--select-all', countryMismatchPopupEl)
                selectAllBtn.addEventListener('click', (e) => {
                    e.preventDefault()

                    countryMismatchModal.hide();
                    let langModalEl = document.getElementsByClassName('js-lang-modal');
                    const langModal = Modal.getInstance(langModalEl[0]);
                    langModal.show()
                })

                const submitButton = findIn('.js-countryMismatchPopup--submit', countryMismatchPopupEl)
                submitButton.addEventListener('click', (e) => {
                    const selectedCountry = findIn('input[type="radio"][name="selectCountry"]:checked', countryMismatchPopupEl).value
                    if (selectedCountry !== res.defaultCountry) {
                        window.location.href = res.geoLocatedLink
                    }
                    countryMismatchModal.hide();
                });
            })

        }
    })
}
