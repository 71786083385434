"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement) {
    const splideOptions = {
        type: 'slide',
        gap: 'calc(10rem/16)',
        padding: 'calc(20rem/16)',
        mediaQuery: 'min',
        drag: 'free',
        arrows: false,
        pagination: false,
        autoWidth: true,
        breakpoints: {
            1200: {
                destroy: 'completely'
            }
        }
    }

    slider = new Splide( sliderElement, splideOptions ).mount();
}
