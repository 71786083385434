import {onFind} from "../init-modules-in-scope";
import {closest, findIn, on, removeClass, addClass} from "../dom-utils";

const defaultSelectors = {
    input: '.js-file-input',
    label: '.js-file-input__text'
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.input, function ($base) {
        createInputText(
            $base,
            {...defaultSelectors, ...selectors}
        )
    })
};

export function createInputText($base, selectors = defaultSelectors) {
    on('change', function() {
        let $formGroup  = closest('.form-group', $base),
            $input      = findIn('[type="file"]', $base),
            $label      = findIn(selectors.label, $base),
            numFiles    = $input.files ? $input.files.length : 1,
            label       = $input.value.replace(/\\/g, '/').replace(/.*\//, '')

        if (numFiles === 0) {
            label = $base.getAttribute('data-file-input-default-text');
            removeClass('has-value', $input)
            removeClass('has-value', $formGroup)
        } else if (numFiles > 1) {
            label = $base.getAttribute('data-file-input-multiple-text').replace('[count]', numFiles);
            addClass('has-value', $input)
            addClass('has-value', $formGroup)
        }

        $label.innerText = label;
    }, $base)
}