"use strict";

import $ from 'jquery';

let promise;

export default function () {
    if (promise) {
        return promise;
    }

    promise = $.Deferred();

    // because jquery-ui-datepicker-with-i18n requires these global vars
    window.$ = window.$ || $;
    window.jQuery = window.jQuery || $;

    window.$.fn.zIndex = function() {
        /* backfill old zIndex function for old jquery ui datepicker*/
        return this.css("zIndex");
    };

    import("../jquery-ui-datepicker-with-i18n").then(function () {
        if (_config.lang && _config.lang !== 'en') {
            import('../jquery-ui-datepicker-with-i18n/ui/i18n/jquery.ui.datepicker-' + _config.lang + '.js').then(function () {
                promise.resolve();
            }).catch(function () {
                /*fallback if there is no package*/
                promise.resolve();
            });
        } else {
            promise.resolve();
        }
    }).catch(function (error) {
        promise.reject();
        console.error('Could not load ../jquery-ui-datepicker-with-i18n', error);
    });

    return promise;
};