"use strict";

import {add} from '../libs/@elements/scroll-animations';
import {findAll} from "../libs/@elements/dom-utils";

export function init() {
    add(
        findAll('.js-bottle-animation'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

// gets element -> returns number between 0 and 1
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 10 * 9;
    const end = window.innerHeight / 2;

    return {
        opacity: 1 - Math.max(
            Math.min(
                (element.getBoundingClientRect().top - end) / (start - end),
                1
            ),
            0
        ),
        transform: Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end), 1),0) * element.getAttribute('data-transform-value') ?? 35,
        rotate: Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end), 1), 0) * element.getAttribute('data-rotate-deg')
    }

}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    element.style.opacity = progress.opacity;

    switch (element.getAttribute('data-start-position')) {
        case 'right':
            element.style.transform = 'translate3d('+ progress.transform +'px, 0, 0) rotate('+ progress.rotate +'deg)';
            break;
        case 'left':
            element.style.transform = 'translate3d(-'+ progress.transform +'px, 0, 0) rotate(-'+ progress.rotate +'deg)';
            break;
        default:
            element.style.transform = 'translate3d(0, -'+ progress.transform +'px, 0) rotate(-'+ progress.rotate +'deg)';
    }
}