import {onFind} from "../../../libs/@elements/init-modules-in-scope";

const Selectors = {
    Input: '#mai__friendlycaptcha-overlay-toggle',
    Labels: '.mai__friendlycaptcha-overlay-icon-wrapper',
    Overlays: '.mai__friendlycaptcha-overlay'
}

export function init() {
    onFind(Selectors.Input, (element) => {
        document.addEventListener('click', event => {
            if (!window.matchMedia('(min-width: 768px)').matches) return
            if (!event.target) return

            if (event.target.closest(Selectors.Labels) ||
                event.target.closest(Selectors.Overlays) ||
                event.target === element) {
                return
            }

            element.checked = false
        })
    });
}
