'use strict'

import $ from "jquery";
import { addLoadingContainer, removeLoadingContainer } from "./loading";
import throwError from '../libs/@elements/throw-error'
import simpleFetch from "../libs/@elements/fetch";
import 'url-search-params-polyfill'; // Edge Polyfill
import formDataEntries from 'form-data-entries';
import {throttle} from "throttle-debounce";
import initModulesInScope from "../libs/@elements/init-modules-in-scope";
import {isParsleyForm, isValid, whenValid, loadParsley} from "../libs/@elements/parsley-bootstrap-validation";
import loadScript from "../libs/@elements/load-script";


export function init(){
    // save scroll pos for removing scrollbar when modal opend (ios .modal-open fix)
    function scrollListener(){
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    }

    scrollListener();
    window.addEventListener('scroll', throttle(100, scrollListener));
}

export function initInScope($scope){
    const
        $raffleTeaser   = $scope.find('.js-raffle__teaser');

    if($raffleTeaser.length){
        if($raffleTeaser.length > 1){
            throwError("raffle: too many raffles one one page detected!")
        }

        const
            $raffleBtn      = $raffleTeaser.find('.js-raffle__start');
        let pendingRequest;

        $raffleBtn.on('click', (e) => {
            e.preventDefault();
            if(pendingRequest){
                return
            }
            addLoadingContainer($raffleBtn,[],false);
            let url = $raffleBtn.data('url') ? $raffleBtn.data('url') : throwError('raffle: no url provided');

            loadParsley().then(function () {
                pendingRequest = simpleFetch(url).then((res) => {
                    return res.clone().json()
                }).then((res) => {
                    if (res.success) {
                        const $raffleContainer = $(res.config.templates.container);
                        initRaffle({$teaser: $raffleTeaser, $raffleBtn, $raffleContainer}, res.config);
                        if (!$('.modal--raffle-cancel').length && res.config.templates.modal.abort) {
                            $('#lightbox-container').append($(res.config.templates.modal.abort))
                        }
                    }
                    pendingRequest = null;
                }).catch((error) => {
                    pendingRequest = null;
                    console.error(error);
                }).finally(() => {
                    removeLoadingContainer($raffleBtn)
                })
            }).catch(() => {
                pendingRequest = null;
                removeLoadingContainer($raffleBtn);
            })
        });

        optionallyShowRaffle($raffleBtn);
    }

    function optionallyShowRaffle($button) {
        let searchParams = new URLSearchParams(window.location.search)
        let open_param = searchParams.get('show')
        if (open_param != null && open_param === '1') {
            $button.trigger('click');
        }
    }
}

function initRaffle({$teaser, $raffleBtn, $raffleContainer}, config) {

    let
        loading = false,
        currentStep = 1,
        tabConfig = config.steps,
        $currentStep = null,
        $content,
        $scrollContainer,
        $tabs = $([]),
        $tabContainer;

    createRaffle();

    function initStep() {
        // init step and bind submitStep
        const
            $form = $raffleContainer.find('.js-raffle__form'),
            $nextBtn = $tabContainer.parent().find('.js-raffle__next'),
            $prevBtn = $tabContainer.parent().find('.js-raffle__prev'),
            $nextMobile = $raffleContainer.find('.js-raffle__mobile-submit'),
            validationMap = new Map();

        $form.on('submit', (e) => submitStep(e));

        $form.find('input').on('input', (e) => {
            const $input = $(e.target);

            if($input.attr('required') === 'required'){
                if($input.is(':checkbox') || $input.is(':radio')){
                    const $inputs = $input.closest('.form-group').find("input[name='" + $input.attr('name') + "']");

                    if($inputs.is(':checked')){
                        validationMap.set( $input.attr('name'), true);
                    }else{
                        validationMap.set( $input.attr('name'), false);
                    }
                }else{
                    if($input.val()){
                        validationMap.set( $input.attr('name'), true);
                    }else{
                        validationMap.set( $input.attr('name'), false);
                    }
                }

                checkValid()
            }
        }).each((_, el) => {
            const $input = $(el);
            if(!$input.attr('required')){
                return
            }

            validationMap.set( $input.attr('name'), false);
        });

        function checkValid(){
            let isValid = true;

            // if there is no required field - true
            if(validationMap.size === undefined){
                return true;
            }

            // check all fields if one required field is not set - false
            validationMap.forEach((input) => {
                if(input === false){
                    isValid = false;
                }
            });

            if(isValid){
                $nextMobile.removeClass('disabled mai__btn--disabled')
            }else{
                $nextMobile.addClass('disabled mai__btn--disabled')
            }
        }

        $nextBtn.removeClass('raffle__tabs-btn--remove raffle__tabs-btn--inactive');
        $prevBtn.removeClass('raffle__tabs-btn--remove raffle__tabs-btn--inactive');

        if(currentStep + 1 >= Object.keys(config.steps).length){
            $nextBtn.addClass('raffle__tabs-btn--remove')
        }
        if(currentStep <= 1){
            $prevBtn.addClass('raffle__tabs-btn--remove')
        }

        // check if next step is clickable
        if(!tabConfig[currentStep + 1]?.clickable){
            $nextBtn.addClass('raffle__tabs-btn--inactive')
        }else{
            $nextBtn.removeClass('raffle__tabs-btn--inactive')
        }

        moveTabInsideContainer($tabs.eq(currentStep - 1));
        checkValid();
    }


    let tokenPromise;
    /**
     * SubmitStep submits a step with the form and action
     * @param e
     * @param action | could be 'commit' to validate form or 'navigate' to go back
     * @param payload can be an object for more parameters to add to the fetch
     */
    async function submitStep(e, action = 'commit', payload = {}){

        e.preventDefault();
        if(loading){
            return;
        }

        const $form = $currentStep.find('.js-raffle__form');
        if(action !== 'navigate'){
            if (isParsleyForm($form) && !isValid($form)) {
                return;
            }
        }

        let urlSearchParams = new URLSearchParams(formDataEntries($form[0]));
        let formData = new FormData();
        urlSearchParams.forEach(function(value, key) {
            formData.append(key, value);
        });

        formData.append('currentStep', currentStep);
        formData.append('action', action);

        $form.find("input[type=file]").each((_, fileInput) => {
            let name = fileInput.getAttribute('name');
            formData.set(name, fileInput.files[0]);
        });

        addLoadingContainer($content, [true, false]);
        loading = true;

        if($form.find('.recaptcha-response').length){
            const apiKey = _config.reCaptchaKey;

            if (!apiKey) {
                return $.Deferred().reject(new Error('reCaptcha key is not set. Please set _config.reCaptchaKey'));
            }

            await loadScript('https://www.google.com/recaptcha/api.js?render=' + apiKey);

            if (tokenPromise) {
                tokenPromise.abort();
                tokenPromise = null;
            }

            tokenPromise = await new Promise((resolve, reject) => {
                grecaptcha.ready(async function () {

                    tokenPromise = await grecaptcha.execute(apiKey, {action: 'raffle_submit_submit'})
                        .then(function (token) {
                            formData.set('g-recaptcha-response', token);
                        })
                        .catch(function (e) {
                            console.error('error', e);
                            reject();
                        })
                        .then(function () {
                            tokenPromise = null;
                            resolve();
                        });
                });
            });
        }



        // Add parameters from payload
        Object.entries(payload).forEach(([key, value]) => {
            formData.append(key, value)
        });

        let request = fetch(config.steps[currentStep].url, {
            method: 'POST',
            body: formData,
            credentials: 'same-origin', /** this is needed so cookies get sent with the fetch api automatically on the same-origin policy in old browsers **/
        }).then((res) => {return res.clone().json()}).then((res) => {
            if(res.success){
                if(res.raffleCompleted){
                    closeRaffle('finished');
                    $teaser.html($(res.html));
                    initModulesInScope($teaser);
                    optionallyScrollToRaffle();
                    return;
                }

                tabConfig = res.tabstate;

                let $oldStep = $content.find('.raffle__step--active');
                $currentStep = $(res.html);
                res.currentStep = parseInt(res.currentStep);
                if(currentStep > res.currentStep){
                    //go backwards
                    $oldStep.one('transitionend webkitTransitionEnd oTransitionEnd', function () {
                        setTimeout(() => {
                            $oldStep.remove()
                        }, 1)
                    });
                    $oldStep.removeClass('raffle__step--active');
                    $currentStep.addClass('raffle__step--deactivate');
                    $content.append($currentStep);
                    $currentStep[0].offsetHeight;
                    $currentStep.removeClass('raffle__step--deactivate').addClass('raffle__step--active')
                }
                else if(currentStep === res.currentStep){
                    // same step - that means error - done animate
                    $oldStep.remove();
                    $currentStep.addClass('raffle__step--active')
                    $content.append($currentStep);
                }else{
                    //go forwards
                    $oldStep.one('transitionend webkitTransitionEnd oTransitionEnd', function () {
                        $oldStep.remove()
                    });
                    $oldStep.removeClass('raffle__step--active').addClass('raffle__step--deactivate')
                    $content.append($currentStep);
                    $currentStep[0].offsetHeight;
                    $currentStep.addClass('raffle__step--active')
                }
                currentStep = res.currentStep;

                initModulesInScope($currentStep);
                initStep();
                setSteps(tabConfig);
                loading = false;

                if ($scrollContainer) {
                    $scrollContainer.scrollTop(0);
                }
            }
        }).catch((error) => {
            console.error(error);
            loading = false;
        }).finally(() => {
            removeLoadingContainer($content);
        });

        return request;
    }

    function createRaffle() {
        $('#lightbox-container').append($raffleContainer);
        $content = $raffleContainer.find('.js-raffle__content');
        $scrollContainer = $raffleContainer.find('.js-raffle__scroll-container');

        openRaffle();

        addLoadingContainer($content);

        loading = true;
        simpleFetch(config.steps[currentStep].url, {method: 'POST'}).then((res) => {return res.clone().json()}).then((res) => {
            if(res.success){
                $currentStep = $(res.html);
                $currentStep.addClass('raffle__step--active');
                $content.append($currentStep);
                tabConfig = res.tabstate;
                initModulesInScope($currentStep);
                initStep();
            }else{

            }
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            removeLoadingContainer($content);
            loading = false;
        });

        createSteps();
        setSteps(config.tabstate);
        addResizeListener();

        $raffleContainer.find('.js-raffle__mobile-submit').on('click', (e) => submitStep(e, 'commit'));
        $raffleContainer.find('.js-raffle__mobile-abort').on('click', (e) => {
            e.preventDefault();

            const $modal = $('.modal--raffle-cancel');
            const $modalBackdrop = $('.modal--raffle-cancel + .modal-backdrop');
            $modalBackdrop.addClass('show');

            $modal.show().find('.js-raffle__mobile-abort-commit').on('click', () => {
                $modal.hide();
                $modalBackdrop.removeClass('show');
                closeRaffle('hide')
            });

            $modal.find('[data-dismiss=modal], [data-bs-dismiss=modal]').on('click', () => {
                $modal.hide();
                $modalBackdrop.removeClass('show');
            })
        })
    }

    function addResizeListener(){
        $(window).resize(throttle(100, () => {
            calculateProgress();
            checkOverflowBorders();
            moveTabInsideContainer($tabs.eq(currentStep - 1));
        }))
    }

    function setSteps(state) {
        calculateProgress();

        Object.entries(state).forEach(([key, value]) => {
            const $tab = $tabs.eq(key - 1);
            //set initial state
            $tab.find('a')
                .removeClass('raffle__tab-link--active')
                .removeClass('raffle__tab-link--progress');

            if(key <= currentStep){
                $tab.find('a').addClass('raffle__tab-link--progress');
            }

            if(value.active){
                $tab.find('a').addClass('raffle__tab-link--active');
            }
            if(value.clickable){
                $tab.find('a').addClass('raffle__tab-link--clickable');
            }
        })
    }

    /**
     * calculates the progress of the tabs and sets the width of the progressbar
     */
    function calculateProgress() {
        const $progress = $('.js-raffle__tabs-progress');
        let offset = getOffset($tabs.eq(currentStep - 1))
        $progress.width(offset)
    }

    /**
     * calculates the left offset to its parent + element width from an element / i.e. inisde a scrollable div
     * @param $element
     * @returns {*}
     */
    function getOffset($element) {
        let childPos = $element.offset();
        let parentPos = $element.parent().offset();
        return childPos.left - parentPos.left + $tabContainer[0].scrollLeft + $element.width()
    }

    function createSteps() {
        // create all tabs
        const template = config.templates.li;
        $tabContainer = $raffleContainer.find('.js-raffle__tabs');

        Object.entries(config.steps).forEach(([key, values]) => {
            const $tab = $(parseStringTemplate(template, values));
            $tabs = $tabs.add($tab);
            $tabContainer.append($tab)
        })

        bindLinkEvents();
        bindCloseEvents();
        bindScrollEvents();
        bindNaviation();
        checkOverflowBorders();

        function bindLinkEvents() {
            // managing clicks on links
            // detect click and not move
            const delta = 6;
            let startX;
            let startY;

            $tabs.on('mousedown', (event) => {
                startX = event.pageX;
                startY = event.pageY;
            });
            $tabs.on('mouseup', (event) => {
                let $linkElement = $(event.target);

                const diffX = Math.abs(event.pageX - startX);
                const diffY = Math.abs(event.pageY - startY);

                if (diffX < delta && diffY < delta) {
                    if($linkElement.hasClass('raffle__tab-link--clickable')){
                        submitStep(event, 'navigate', {'navigateTo': $linkElement.data('id')})
                        moveTabInsideContainer($linkElement.parent());
                    }
                }
            });
            $tabs.on('click', (e) =>  {
                e.preventDefault()
            })
        }

        function bindCloseEvents(){
            //click on close
            $raffleContainer.find('.js-raffle__close').on('click', () => closeRaffle('hide'))
        }

        /**
         * binds events for the scrollable tabs
         */
        function bindScrollEvents(){
            // scrolling the div
            let isDown = false;
            let startX;
            let scrollLeft;
            const $overlay = $("<span class='kinetic-overlay'/>").css({
                "user-select": "none",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1100
            });

            $tabContainer.on('mousedown', (e) => {
                isDown = true;
                $tabContainer.addClass('scrolling').prepend($overlay);
                startX = e.pageX - $tabContainer[0].offsetLeft;
                scrollLeft = $tabContainer[0].scrollLeft;
            });
            $tabContainer.on('mouseleave', () => {
                isDown = false;
                $tabContainer.removeClass('scrolling').find('.kinetic-overlay').remove();
            });
            $tabContainer.on('mouseup', () => {
                isDown = false;
                $tabContainer.removeClass('scrolling').find('.kinetic-overlay').remove();
            });
            $tabContainer.on('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - $tabContainer[0].offsetLeft;
                const walk = (x - startX);
                $tabContainer[0].scrollLeft = scrollLeft - walk;

                checkOverflowBorders();
            });
        }

        /**
         * Binds the mobile nav button click events
         */
        function bindNaviation() {
            const
                $nextBtn = $tabContainer.parent().find('.js-raffle__next'),
                $prevBtn = $tabContainer.parent().find('.js-raffle__prev');

            $nextBtn.on('click', (e) => {
                if(currentStep + 1 < Object.keys(config.steps).length && !$nextBtn.hasClass('raffle__tabs-btn--inactive')){
                    submitStep(e, 'navigate', {'navigateTo': currentStep + 1})
                }
            });
            $prevBtn.on('click', (e) => {
                if(currentStep > 1){
                    submitStep(e, 'navigate', {'navigateTo': currentStep - 1})
                }
            })
        }

    }

    /**
     * move tab inside scrollable div if its either outside to the left or right
     * @param $tab
     */
    function moveTabInsideContainer($tab) {
        const offsetRight = getOffset($tab);
        const offsetLeft = offsetRight - $tab[0].offsetWidth;
        const containerScrollAndWidth = $tabContainer[0].scrollLeft + $tabContainer[0].offsetWidth;

        if(offsetRight > containerScrollAndWidth){
            // outside to the right
            $tabContainer.animate({ scrollLeft: Math.ceil(offsetRight - $tabContainer[0].offsetWidth)}, 333, checkOverflowBorders);
        }else if(offsetLeft < $tabContainer[0].scrollLeft){
            // outside to the left
            $tabContainer.animate({ scrollLeft: offsetLeft }, 333, checkOverflowBorders);
        }
    }

    function checkOverflowBorders(){
        if( $tabContainer[0].scrollLeft === 0) {
            $tabContainer.parent().removeClass('raffle__tabcontainer--morecontent-left');
        }else{
            $tabContainer.parent().addClass('raffle__tabcontainer--morecontent-left');
        }

        if($tabContainer[0].scrollLeft + $tabContainer[0].offsetWidth === $tabContainer[0].scrollWidth){
            $tabContainer.parent().removeClass('raffle__tabcontainer--morecontent-right');
        }else{
            $tabContainer.parent().addClass('raffle__tabcontainer--morecontent-right');
        }
    }


    function openRaffle() {
        // open raffle container
        var startRect = $('.js-raffle__teaser')[0].getBoundingClientRect(),
            endRect = $raffleContainer[0].getBoundingClientRect();

        return new Promise(resolve => {
            $raffleContainer.addClass('raffle__container--loaded');

            $raffleContainer.one('transitionend', () => {
                resolve();
            });

            //save scroll pos and remove default scrollbar (for ios)
            const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
            const body = document.body;
            body.style.position = 'fixed';
            body.style.width = '100%';
            body.style.top = `-${scrollY}`;
        })
    }

    function closeRaffle(closeEvent = '') {
        //set back to original scroll position
        const body = document.body;
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);

        // set focus to teaser so you cant access raffle after closing
        const $teaser = $('.js-raffle__teaser');
        $teaser.focus();

        var startRect = $raffleContainer[0].getBoundingClientRect(),
            endRect = $teaser[0].getBoundingClientRect();

        let transitionDuration = 0;
        let computedStyles = window.getComputedStyle($raffleContainer[0]);
        let transitionDurationString = computedStyles.getPropertyValue('transition-duration').match(/(\d+)/);

        if (transitionDurationString) {
            transitionDuration = parseInt(transitionDurationString[1], 10) * 1000;
        }

        optionallyHistoryBack(closeEvent);

        return new Promise((resolve) => {
            $raffleContainer.removeClass('raffle__container--loaded');
            $raffleContainer.one('transitionend', () => {
                setTimeout(() => {
                    $raffleContainer.remove();
                    resolve();
                }, transitionDuration)
            })
        });
    }

    function optionallyHistoryBack(closeEvent = '') {
        if (closeEvent !== 'finished') {
            let searchParams = new URLSearchParams(window.location.search)
            let back_param = searchParams.get('return')
            if (back_param != null && back_param === '1') {
                history.back();
            }
        }
    }

    function optionallyScrollToRaffle() {
        let searchParams = new URLSearchParams(window.location.search)
        let open_param = searchParams.get('show')
        if (open_param != null && open_param === '1') {
            $('html, body').animate({
                'scrollTop':   $('.js-raffle__teaser').offset().top - $('.js-header').height() - 50
            }, 0);
        }
    }
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

function parseStringTemplate(str, obj) {
    let parts = str.split(/\$\{(?!\d)[\wæøåÆØÅ]*\}/);
    let args = str.match(/[^{\}]+(?=})/g) || [];
    let parameters = args.map(argument => obj[argument] || (obj[argument] === undefined ? "" : obj[argument]));
    return String.raw({ raw: parts }, ...parameters);
}
