"use strict";

import throwError from "../../libs/@elements/throw-error"
import {onFind} from "../../libs/@elements/init-modules-in-scope";
import {findIn, findAllIn} from "../../libs/@elements/dom-utils";
import * as slider from './slider';

const Selectors = {
    Container: '.mai__m52-social-wall',
    Slider: '.mai__m52-social-wall__content',
    SlideItem: '.mai__m52-social-wall__item'
}

let containerEl;
let sliderEl;


export function init () {
    onFind(Selectors.Container, (element) => {
        containerEl = element ? element : throwError("M52 Social Wall Slider JS – Container missing");
        sliderEl = findIn(Selectors.Slider, containerEl) ? findIn(Selectors.Slider, containerEl) : throwError("M52 Social Wall JS – Slider missing")   
        sliderEl && slider.init(sliderEl);
    })
}
