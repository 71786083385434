"use strict";

import offset from '../../helpers/offset'
import outerWidth from '../../helpers/outerWidth'
import outerHeight from '../../helpers/outerHeight'

function noop() {
}

const ADD_EVENTLISTENER = 'addEventListener';

const REMOVE_EVENTLISTENER = 'removeEventListener';

class MenuAim {
    getDefaults() {
        return {
            rowSelector: "> li",
            submenuSelector: "*",
            submenuDirection: "right",
            tolerance: 75,  // bigger = more forgivey when entering submenu
            enter: noop,
            exit: noop,
            activate: noop,
            deactivate: noop,
            exitMenu: noop,
        }
    }

    constructor(element, options) {
        this.menu = element;
        this.lastDelayLoc = null
        this.timeoutId = null
        this.activeRow = null;
        this.mouseLocs = [];
        this.options = Object.assign({}, this.getDefaults(), options);


        this.MOUSE_LOCS_TRACKED = 3;  // number of past mouse locations to track
        this.DELAY = 300;  // ms delay when user appears to be entering submenu
    }

    init() {
        this.onMouseMoveDocument = (e)=>this.onMouseMoveDocumentInner(e)
        this.onMouseLeaveMenu = (e)=>this.onMouseLeaveMenuInner(e)
        this.onMouseEnterRow = (e)=>this.onMouseEnterRowInner(e)
        this.onMouseLeaveRow = (e)=>this.onMouseLeaveRowInner(e)
        this.onClickRow = (e)=>this.onClickRowInner(e)


        this.toggleEventListeners(true)
    };

    onMouseMoveDocumentInner(e) {
        this.mouseLocs.push({x: e.pageX, y: e.pageY});

        if (this.mouseLocs.length > this.MOUSE_LOCS_TRACKED) {
            this.mouseLocs.shift();
        }
    };

    /**
     * Cancel possible row activations when leaving the menu entirely
     */
    onMouseLeaveMenuInner() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        // If exitMenu is supplied and returns true, deactivate the
        // currently active row on menu exit.
        if (this.options.exitMenu(this)) {
            if (this.activeRow) {
                this.options.deactivate(this.activeRow);
            }

            this.activeRow = null;
        }
    };

    /**
     * Trigger a possible row activation whenever entering a new row.
     */
    onMouseEnterRowInner(ev) {
        if (this.timeoutId) {
            // Cancel any previous activation delays
            clearTimeout(this.timeoutId);
        }

        this.options.enter(ev.currentTarget);
        this.possiblyActivate(ev.currentTarget);
        // this.options.activate(ev.currentTarget);
    };

    onMouseLeaveRowInner(ev) {
        this.options.exit(ev.currentTarget);
    };

    /*
     * Immediately activate a row if the user clicks on it.
     */
    onClickRowInner(ev) {
        // this.activate(ev.currentTarget);
        // const row = closest(ev.target)
        this.activate(ev.target);
    };


    /**
     * Activate a menu row.
     */
    activate(row) {
        // if (row == this.activeRow) {
        //     return;
        // }

        if (this.activeRow) {
            this.options.deactivate(this.activeRow);
        }

        this.options.activate(row);
        this.activeRow = row;
    };

    /**
     * Possibly activate a menu row. If mouse movement indicates that we
     * shouldn't activate yet because user may be trying to enter
     * a submenu's content, then delay and check again later.
     */
    possiblyActivate(row) {
        const delay = this.activationDelay()
            , self = this;

        if (delay) {
            this.timeoutId = setTimeout(function () {
                self.possiblyActivate(row);
            }, delay);
        } else {
            this.activate(row);
        }
    };

    /**
     * Return the amount of time that should be used as a delay before the
     * currently hovered row is activated.
     *
     * Returns 0 if the activation should happen immediately. Otherwise,
     * returns the number of milliseconds that should be delayed before
     * checking again to see if the row should be activated.
     */
    activationDelay() {
        if (!this.activeRow || !this.activeRow.matches(this.options.submenuSelector)) {
            // If there is no other submenu row already active, then
            // go ahead and activate immediately.
            return 0;
        }

        const menuOffset = offset(this.menu),
            upperLeft = {
                x: menuOffset.left,
                y: menuOffset.top - this.options.tolerance
            },
            upperRight = {
                x: menuOffset.left + outerWidth(this.menu),
                y: upperLeft.y
            },
            lowerLeft = {
                x: menuOffset.left,
                y: menuOffset.top + outerHeight(this.menu) + this.options.tolerance
            },
            lowerRight = {
                x: menuOffset.left + outerWidth(this.menu),
                y: lowerLeft.y
            },
            loc = this.mouseLocs[this.mouseLocs.length - 1]

        if (!loc) {
            return 0;
        }

        let prevLoc = this.mouseLocs[0];
        if (!prevLoc) {
            prevLoc = loc;
        }

        if (prevLoc.x < menuOffset.left || prevLoc.x > lowerRight.x ||
            prevLoc.y < menuOffset.top || prevLoc.y > lowerRight.y) {
            // If the previous mouse location was outside of the entire
            // menu's bounds, immediately activate.
            return 0;
        }

        if (this.lastDelayLoc && loc.x === this.lastDelayLoc.x && loc.y === this.lastDelayLoc.y) {
            // If the mouse hasn't moved since the last time we checked
            // for activation status, immediately activate.
            return 0;
        }

        // Detect if the user is moving towards the currently activated
        // submenu.
        //
        // If the mouse is heading relatively clearly towards
        // the submenu's content, we should wait and give the user more
        // time before activating a new row. If the mouse is heading
        // elsewhere, we can immediately activate a new row.
        //
        // We detect this by calculating the slope formed between the
        // current mouse location and the upper/lower right points of
        // the menu. We do the same for the previous mouse location.
        // If the current mouse location's slopes are
        // increasing/decreasing appropriately compared to the
        // previous's, we know the user is moving toward the submenu.
        //
        // Note that since the y-axis increases as the cursor moves
        // down the screen, we are looking for the slope between the
        // cursor and the upper right corner to decrease over time, not
        // increase (somewhat counterintuitively).
        function slope(a, b) {
            return (b.y - a.y) / (b.x - a.x);
        }

        let decreasingCorner = upperRight,
            increasingCorner = lowerRight;

        // Our expectations for decreasing or increasing slope values
        // depends on which direction the submenu opens relative to the
        // main menu. By default, if the menu opens on the right, we
        // expect the slope between the cursor and the upper right
        // corner to decrease over time, as explained above. If the
        // submenu opens in a different direction, we change our slope
        // expectations.
        if (this.options.submenuDirection === "left") {
            decreasingCorner = lowerLeft;
            increasingCorner = upperLeft;
        } else if (this.options.submenuDirection === "below") {
            decreasingCorner = lowerRight;
            increasingCorner = lowerLeft;
        } else if (this.options.submenuDirection === "above") {
            decreasingCorner = upperLeft;
            increasingCorner = upperRight;
        }

        const decreasingSlope = slope(loc, decreasingCorner)
        const increasingSlope = slope(loc, increasingCorner)
        const prevDecreasingSlope = slope(prevLoc, decreasingCorner)
        const prevIncreasingSlope = slope(prevLoc, increasingCorner)

        if (decreasingSlope < prevDecreasingSlope && increasingSlope > prevIncreasingSlope) {
            // Mouse is moving from previous location towards the
            // currently activated submenu. Delay before activating a
            // new menu row, because user may be moving into submenu.
            this.lastDelayLoc = loc;
            return this.DELAY;
        }

        this.lastDelayLoc = null;
        return 0;
    };

    destroy() {
        this.toggleEventListeners(false);
    };

    reset(triggerDeactivate) {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        if (this.activeRow && triggerDeactivate) {
            this.options.deactivate(this.activeRow);
        }

        this.activeRow = null;
    };

    toggleEventListeners(enable) {
        const menuListenerMethod = enable ? ADD_EVENTLISTENER : REMOVE_EVENTLISTENER;
        this.menu[menuListenerMethod]('mouseleave', this.onMouseLeaveMenu);

        this.menu.querySelectorAll(':scope ' + this.options.rowSelector).forEach((el) => {
            const elementListenerMethod = enable ? ADD_EVENTLISTENER : REMOVE_EVENTLISTENER;
            el[elementListenerMethod]('mouseenter', this.onMouseEnterRow);
            el[elementListenerMethod]('mouseleave', this.onMouseLeaveRow);
            el[elementListenerMethod]('click', this.onClickRow);
        })

        const bodyListenerMethod = enable ? ADD_EVENTLISTENER : REMOVE_EVENTLISTENER;
        document.body[bodyListenerMethod]('mousemove', this.onMouseMoveDocument);
    }
}

export default MenuAim;