"use strict";

window.missingTranslations = new Set();

export function translate(string) {
    const translationObject = window._translations

    if (typeof translationObject !== 'object') {
        console.warn('Global translation object _translations missing');
        return string;
    }

    if (!translationObject[string]) {
        if (!window.missingTranslations.has(string)) {
            console.warn('Could not translate "' + string + '". Translation not found in translation object', translationObject);
            window.missingTranslations.add(string);
        }

        return string;
    }

    return translationObject[string];
}