"use strict";

import throwError from "../../libs/@elements/throw-error"
import {onFind} from "../../libs/@elements/init-modules-in-scope";
import {find, findIn, findAllIn, hasClass, trigger, on, addClass, removeClass} from "../../libs/@elements/dom-utils";
import {init as sliderInit} from './slider';
import {init as navSliderInit} from './nav-slider';

const Selectors = {
    Container: '.mai__m4-shop-the-look',
    Slider: '.mai__m4-shop-the-look__slider',
    SliderItem: '.mai__m4-shop-the-look__slider-item',
    Nav: '.mai__m4-shop-the-look__nav-slider',
    NavSlider: '.mai__m4-shop-the-look__nav-slider.splide',
    NavItem: '.mai__m4-shop-the-look__nav-slider-item',
    Toggle: '.mai__m4-shop-the-look__image-dot-toggle',
    ToggleMobile: '.mai__m4-shop-the-look__image-dot-toggle--mobile',
}

const States = {
    ToggleMobile: 'mai__m4-shop-the-look__image-dot-toggle--mobile',
    NavItemActive: 'is-active',
}

const Attributes = {
    ProductId: 'data-product-id',
    Checked: 'checked'
}

const UPDATE_PRODUCT_ID_EVENT_NAME = 'updated:activeProductId'
const EVENT_TRIGGER_NAVSLIDER = 'onNavSliderMove'
const EVENT_TRIGGER_SLIDER = 'onSliderMove'
const EVENT_TRIGGER_TOGGLECHANGE = 'onToggleChange'

export class M4ShopTheLook {
    constructor(element) {
        this.uuid = '-' + (Math.random(100) * 100);
        this.containerEl = element ? element : throwError("M4 Shop the look JS – Container missing");
        this.sliderEl = findIn(Selectors.Slider, this.containerEl) ? findIn(Selectors.Slider, this.containerEl) : throwError("M4 Shop the look JS – Slider missing");
        this.navSliderEl = findIn(Selectors.NavSlider, this.containerEl) ? findIn(Selectors.NavSlider, this.containerEl) : false;
        this.navEl = findIn(Selectors.Nav, this.containerEl) ? findIn(Selectors.Nav, this.containerEl) : throwError("M4 Shop the look JS – Nav missing");
        this.activeProductId = undefined;
        this.productsIdsArray = [];
        this.toggleElArr = [];
        this.sliderItemElArr = [];
        this.navItemElArr = [];
        this.lastEventTrigger = undefined;

        if (!this.navSliderEl) console && console.warn && console.warn("M4 Shop the look JS – NavSlider missing");

        if (this.sliderEl) this.slider = sliderInit(this.sliderEl)
        if (this.navSliderEl) this.navSlider = navSliderInit(this.navSliderEl)

        this.sliderItemElArr = findAllIn(Selectors.SliderItem, this.sliderEl);
        this.toggleElArr = findAllIn(Selectors.Toggle, this.containerEl);
        this.navItemElArr = findAllIn(Selectors.NavItem, this.navEl);

        this.fillProductIdsArray();
        this.activeProductId = this.getValidActiveProductId();

        this.toggleElArr.forEach(toggleEl => toggleEl.addEventListener('change', this.onToggleChange.bind(this)));
        this.navSlider && this.navSlider.on('move', (newIndex, prevIndex, destIndex) => {
            this.lastEventTrigger !== EVENT_TRIGGER_SLIDER + this.uuid && this.onNavSliderMove(newIndex)
            this.lastEventTrigger = undefined
        });
        this.slider && this.slider.on('move', (newIndex, prevIndex, destIndex) => {
            this.lastEventTrigger !== EVENT_TRIGGER_NAVSLIDER + this.uuid && this.onSliderMove(newIndex)
            this.lastEventTrigger = undefined
        });
        on(UPDATE_PRODUCT_ID_EVENT_NAME + this.uuid, (e) => this.onActiveProductIdUpdate(e), this.containerEl);
    }

    onToggleChange(event) {
        if (!(event.target instanceof HTMLInputElement)) {
            throwError("M4 Shop the look JS – Not a HTML input element, cant update active product id")
        }

        this.activeProductId = event.target.getAttribute(Attributes.ProductId) ? event.target.getAttribute(Attributes.ProductId) : throwError("M4 Shop the look JS – HTML input element has no product id")
        this.lastEventTrigger = EVENT_TRIGGER_TOGGLECHANGE + this.uuid
        trigger(UPDATE_PRODUCT_ID_EVENT_NAME + this.uuid, event.target);
    }

    onNavSliderMove(newIndex) {
        this.activeProductId = this.productsIdsArray[newIndex] ? this.productsIdsArray[newIndex] : throwError("M4 Shop the look JS – invalid active product id");
        this.lastEventTrigger = EVENT_TRIGGER_NAVSLIDER + this.uuid
        trigger(UPDATE_PRODUCT_ID_EVENT_NAME + this.uuid, this.navSliderEl);
    }

    onSliderMove(newIndex) {
        this.activeProductId = this.productsIdsArray[newIndex] ? this.productsIdsArray[newIndex] : throwError("M4 Shop the look JS – invalid active product id");
        this.lastEventTrigger = EVENT_TRIGGER_SLIDER + this.uuid
        trigger(UPDATE_PRODUCT_ID_EVENT_NAME + this.uuid, this.sliderEl);

    }

    onActiveProductIdUpdate(e) {
        this.syncActiveToggleElements();
        this.setNavLabelActive();

        this.lastEventTrigger !== EVENT_TRIGGER_NAVSLIDER + this.uuid && this.scrollNavSliderToActiveIndex();
        this.lastEventTrigger !== EVENT_TRIGGER_SLIDER + this.uuid && this.scrollSliderToActiveIndex();
    }

    scrollNavSliderToActiveIndex() {
        if (!this.navSlider) return;
        const activeProductIdIndex = this.productsIdsArray.indexOf(this.activeProductId) > -1 ? this.productsIdsArray.indexOf(this.activeProductId) : throwError("M4 Shop the look JS – Active product id not in product id array, is invalid")
        this.navSlider.go(activeProductIdIndex)
    }

    scrollSliderToActiveIndex() {
        if (!this.slider) return;
        const activeProductIdIndex = this.productsIdsArray.indexOf(this.activeProductId) > -1 ? this.productsIdsArray.indexOf(this.activeProductId) : throwError("M4 Shop the look JS – Active product id not in product id array, is invalid")
        this.slider.go(activeProductIdIndex)
    }

    setNavLabelActive() {
        this.navItemElArr.forEach(navItemEl => {
            navItemEl.getAttribute(Attributes.ProductId) && navItemEl.getAttribute(Attributes.ProductId) === this.activeProductId ? addClass(States.NavItemActive, navItemEl) : removeClass(States.NavItemActive, navItemEl)
        });
    }

    // Syncs desktop and mobile input toggle elements
    syncActiveToggleElements() {
        this.toggleElArr.forEach(toggleEl => {
            toggleEl.getAttribute(Attributes.ProductId) && toggleEl.getAttribute(Attributes.ProductId) === this.activeProductId ? toggleEl[Attributes.Checked] = true : toggleEl[Attributes.Checked] = false
        });
    }

    fillProductIdsArray() {
        this.sliderItemElArr.forEach(sliderItemEl => sliderItemEl.getAttribute(Attributes.ProductId) && this.productsIdsArray.push(sliderItemEl.getAttribute(Attributes.ProductId)));
    }

    getValidActiveProductId() {
        const firstProductId = this.sliderItemElArr[0].getAttribute(Attributes.ProductId);

        return firstProductId && this.productsIdsArray.indexOf(firstProductId) > -1 ? firstProductId : throwError("M4 Shop the look JS – Could not determine active product id")
    }
}

export function init() {
    onFind(Selectors.Container, (element) => {
        new M4ShopTheLook(element)
    })
}
